export const fieldsValues = [
  'string', 'number', 'boolean', 'date',
];

export const fieldsLabelValue = [
  {
    label: 'Cadena de Texto',
    value: 'string',
  },
  {
    label: 'Número',
    value: 'number',
  },
  {
    label: 'Boleano',
    value: 'boolean',
  },
  {
    label: 'Fecha',
    value: 'date',
  },
  {
    label: 'Relación de Base de Datos',
    value: 'relation',
  },
  {
    label: 'Usuario',
    value: 'user',
  },
  {
    label: 'Hora',
    value: 'hour',
  },
  {
    label: 'Latitud & Longitud',
    value: 'coordinates',
  },
  {
    label: 'Texto enriquecido',
    value: 'textrich',
  },
  {
    label: 'Lista de Colección de datos',
    value: 'arrayDatabase',
  },
  {
    label: 'Carrito de compras de Colección de datos',
    value: 'cartArray',
  },
];

export const stringTypeLabelValue = [
  {
    label: 'Basica',
    value: 'standard',
  },
  {
    label: 'Correo',
    value: 'email',
  },
  {
    label: 'Seleccion',
    value: 'select',
  },
  {
    label: 'Telefono',
    value: 'phone',
  },
  {
    label: 'Link/Direccion Web',
    value: 'url',
  },
  {
    label: 'Area de Texto',
    value: 'textarea',
  },
];

export const numberTypeLabelValue = [
  {
    label: 'Basica',
    value: 'standard',
  },
  {
    label: 'Moneda',
    value: 'money',
  },
];

export const basicFieldsLabelValue = [
  {
    label: 'Cadena de Texto',
    value: 'string',
  },
  {
    label: 'Número',
    value: 'number',
  },
  {
    label: 'Boleano',
    value: 'boolean',
  },
];

export const typeFormField = (text) => {
  if (text === 'number') {
    return 'number';
  } if (text === 'date') {
    return 'date';
  } if (text === 'boolean') {
    return 'checkbox';
  }
  return 'text';
};

export const typeNormalValue = [
  {
    label: 'Cadena de Texto',
    value: 'string',
  },
  {
    label: 'Número',
    value: 'number',
  },
  {
    label: 'Boleano',
    value: 'boolean',
  },
];

export const isNormalTypeFields = (type) => type === 'string' || type === 'number' || type === 'boolean';
