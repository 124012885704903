import React from 'react';
import {Tooltip} from '@material-ui/core';

export default function SidebarItem({
  isCollapsed,
  tooltipTitle,
  children,
}) {
  if (isCollapsed) {
    return (
      <Tooltip title={tooltipTitle} placement="right">
        <div>{children}</div>
      </Tooltip>
    );
  }
  return <>{children}</>;
}
