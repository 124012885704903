/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import toastr from 'toastr';
import {
  Box, Typography, IconButton, Grid, TextField, MenuItem,
} from '@material-ui/core';
import PlusOneIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  listDatabases, getOneProject, listFieldsDatabase,
  addCronJob,
  listCronJobsByProject,
  isInvalidSlug,
} from 'api-lofty';
import Blockly from 'blockly';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
// import {listDatabases} from '../api/database.api';
// import {getOneProject} from '../api/project.api';
import 'toastr/build/toastr.min.css';
import defineDBBlocks, {addNewVariableWithFieldData} from '../util/customCronsBlockDefinitions';
// import AddParamModal from '../components/AddParamModal';
import blocklyAnalyzer from '../util/blocklyAnalyzer';
import {blocklyVariableSeeker} from '../util/blocklyFunctionsSeeker';
import {generateSlugSampleCron} from '../util/generateSuggestionSlug';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  urlWithMultipleColumns: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'row',
  },
  urlSectionContainer: {
    display: 'flex',
    margin: '2px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  urlFragmentSection: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  urlFragmentLabel: {
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    color: 'rgba(196, 196, 196, 1)',
    fontSize: '15px',
    fontWeight: 500,
  },
  urlInputSection: {
    border: '1px solid rgba(233, 231, 234, 1)',
    borderRadius: '15px',
  },
}));

const CustomCronJob = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [listAllDatabases, setListAllDatabases] = useState([]);
  const [dataFields, setDataFields] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const [cronData, setCronData] = useState([]);
  /* const [urlLabel, setUrlLabel] = React.useState(''); */
  // const [toolboxReact, setToolboxReact] = React.useState([]);
  // const [openAdd, setOpenAdd] = React.useState(false);
  // blockly
  const blocklyRef = useRef();
  let blocklyWorkspace = null;
  const [renderHelper, setRenderHelper] = useState(0);
  const [toolbox, setToolbox] = useState();
  // const [blocklyWorkspaceReact, setBlocklyWorkspaceReact] = useState();
  // const [variableInfoData, setVariableInfoData] = useState([]);
  // end blockly

  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [suggestionSlug, setSuggestionSlug] = useState('');
  const [errorInSlug, setErrorInSlug] = useState(false);
  const [errorMessageSlug, setErrorMessageSlug] = useState('');

  const classes = useStyle();

  function generateSuggestionSlug(value) {
    if (isEditingLabel) {
      const res = generateSlugSampleCron(cronData, value);
      setSuggestionSlug(res);
      setIsEditingLabel(false);
    }
  }

  const verifySlugValue = (value) => {
    if (isInvalidSlug(value)) {
      setErrorMessageSlug('Verifique que no tenga espacios/palabras reservadas');
      setErrorInSlug(true);
    } else {
      setErrorInSlug(false);
      setErrorMessageSlug('');
    }
  };

  const getCollectionFields = async () => {
    // const collectionXfields = [];
    const resDatabases = await listDatabases({token, idProject});
    // eslint-disable-next-line consistent-return
    // const asyncFunctions = [];
    // const asyncFunctions = resDatabases.data.((collection) => async () => {
    // const fields = await listFieldsDatabase({token, idDatabase: collection._id, idProject});
    // if (fields?.data.length > 0) {
    //   const mappedFields = fields
    //     ?.data?.map?.((field) => ({_id: field._id, label: field.label}));
    //   return {collection: {id: collection.name, label: collection.label}, fields: mappedFields};
    // }
    // });
    const collectionData = [];
    setListAllDatabases(resDatabases.data);
    for (const collection of resDatabases.data) {
      const fields = await listFieldsDatabase({token, idDatabase: collection._id, idProject});
      if (fields?.data.length > 0) {
        const mappedFields = fields
          ?.data?.map?.((field) => ({_id: field._id, label: field.label}));
        collectionData.push(
          {collection: {id: collection.name, label: collection.label}, fields: mappedFields},
        );
      }
    }
    setDataFields(collectionData);
  };

  const handleListData = async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resDatabases = await listDatabases({token, idProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Cronjob/`);
      setProject(resProject.project);
      const dataCollection = [];
      for (const collection of resDatabases.data) {
        const fields = await listFieldsDatabase({token, idDatabase: collection._id, idProject});
        if (fields?.data.length > 0) {
          dataCollection.push(collection);
        }
      }
      setData(dataCollection);
    } catch (error) {
      //
    }
  };

  const handleCronList = async () => {
    try {
      const resCronList = await listCronJobsByProject({token, projectId: idProject});
      if (resCronList?.data?.length > 0) {
        setCronData(resCronList.data);
      } else {
        setCronData([]);
      }
    } catch (error) {
      setCronData([]);
      // console.log(error);
    }
  };

  let serialization;

  // eslint-disable-next-line no-unused-vars
  const handleLoad = async () => {
    const mainWorkspace = Blockly.getMainWorkspace();
    const serializer = new Blockly.serialization.blocks.BlockSerializer();
    serializer.load(serialization, mainWorkspace);
  };

  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    const mainWorkspace = Blockly.getMainWorkspace();

    // Blocks
    const serializerBlocks = new Blockly.serialization.blocks.BlockSerializer();
    const stateBlocks = serializerBlocks.save(mainWorkspace);
    serialization = stateBlocks;

    // Variables
    const serializeVars = new Blockly.serialization.variables.VariableSerializer();
    const stateVars = serializeVars.save(mainWorkspace);

    try {
      // Check Before Sending
      const blockInfo = stateBlocks.blocks?.find((index) => (index.type === 'main_block'));
      const searchVars = blocklyVariableSeeker(blockInfo, listAllDatabases, dataFields, [], [], []);
      const infoLink = blocklyAnalyzer(blockInfo, listAllDatabases, dataFields, [], searchVars);
      if (infoLink?.status === 'error') {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${infoLink.info}`), 300);
      } else {
        await addCronJob({
          token,
          projectId: idProject,
          blocks: {
            arrayLogic: stateBlocks,
            createdAt: new Date(),
            variables: stateVars,
            version: '1.0',
          },
          name: e.target.label.value,
          schedule: e.target.schedule.value,
          status: 'active',
          command: suggestionSlug,
        });
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.success('Se agregó el Nuevo Cron Job'), 300);
        history.push(`/dashboard/project/${idProject}/cronjobs`);
      }
    } catch (error) {
      // console.log(error);
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos, no se agrego el nuevo Cron Job'), 300);
      }
    }
  };

  // const handleAddParam = async (e, required) => {
  //   e.preventDefault();
  //   const newBlock = {
  //     type: e.target.type.value,
  //     label: e.target.label.value,
  //     name: e.target.name.value,
  //     typeParam: 'temp',
  //     required,
  //   };
  //   addNewParamBlockBlockly(
  //     blocklyWorkspaceReact,
  //     toolbox,
  //     setToolbox,
  //     setOpenAdd,
  //     newBlock.name,
  //     newBlock.label,
  //     newBlock.type,
  //     toolboxReact,
  //   );
  //   setToolboxReact(((oldTBReact) => ([...oldTBReact, newBlock])));
  // };

  const handleAddVariableWithFields = (variables, stateVars, workspace) => {
    try {
      if (variables?.length > 0) {
        addNewVariableWithFieldData(
          workspace,
          toolbox,
          setToolbox,
          variables,
          stateVars,
        );
      } else {
        addNewVariableWithFieldData(
          workspace,
          toolbox,
          setToolbox,
          [],
          stateVars,
        );
      }
    } catch (error) {
      // console.log(error);
    }
  };

  function handleUpdateBlockly(event) {
    const mainWorkspace = Blockly.getMainWorkspace();
    // const xml = Blockly.Xml.workspaceToDom(blocklyWorkspace);
    // Blocks
    const serializerBlocks = new Blockly.serialization.blocks.BlockSerializer();
    const stateBlocks = serializerBlocks.save(mainWorkspace);
    serialization = stateBlocks;

    // Variables
    const serializeVars = new Blockly.serialization.variables.VariableSerializer();
    const stateVars = serializeVars.save(mainWorkspace);
    const blockInfo = stateBlocks?.blocks?.find((index) => (index.type === 'main_block'));
    if (blockInfo && event) {
      const searchVars = blocklyVariableSeeker(blockInfo, listAllDatabases, dataFields, [], [], []);
      handleAddVariableWithFields(searchVars, stateVars, mainWorkspace);
    }
  }

  useEffect(() => {
    if (!blocklyRef.current) {
      setRenderHelper(renderHelper + 1);
      return;
    }

    const toolbox1 = defineDBBlocks({
      collections: data,
      fields: dataFields,
    });
    // console.log(toolbox1);
    blocklyWorkspace = Blockly.inject('blocklyDiv', {toolbox, maxInstances: {main_block: 1}});
    // defineOnChangeSpawnDBFields(blocklyWorkspace, listFieldsDatabase, token, idProject);
    blocklyWorkspace.addChangeListener(handleUpdateBlockly);
    // setBlocklyWorkspaceReact(blocklyWorkspace);
    setToolbox(toolbox1);
    // TODO: load saved params on startup
    const blankDiv = document.querySelector('.injectionDiv');
    if (blankDiv) {
      blankDiv.style.display = 'none';
    }
  }, [blocklyRef.current, renderHelper]);

  useEffect(() => {
    const loadAll = async () => {
      await handleListData();
      await getCollectionFields();
      await handleCronList();
      setLoading(false);
    };
    loadAll();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      history={history}
      idProject={idProject}
      projectName={project?.name}
      token={token}
    >
      <Box>
        {/* <AddParamModal
          open={openAdd}
          handleClose={() => setOpenAdd(false)}
          handleForm={handleAddParam}
        /> */}
      </Box>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}/cronjobs`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Cron Job
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={classes.textButton}
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          startIcon={<PlusOneIcon />}
          type="submit"
          form="saveform"
        >
          Guardar Cambios
        </Button>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          /* display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center', */
          p: 1,
          m: 1,
        }}
      >
        {/* Se agrego div para el input */}
        <form id="saveform" className="my-4" onSubmit={handleSubmitAdd}>
          <Grid container>
            <Grid item md={4}>
              <div className="mt-4 px-3">
                <TextField
                  fullWidth
                  name="label"
                  type="text"
                  label="Etiqueta"
                  variant="outlined"
                  inputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                  onFocus={() => setIsEditingLabel(true)}
                  onBlur={(e) => generateSuggestionSlug(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="mt-4 px-3">
                <TextField
                  fullWidth
                  name="slug"
                  type="text"
                  label="Slug"
                  variant="outlined"
                  inputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                  value={suggestionSlug}
                  onChange={(e) => setSuggestionSlug(e.target.value)}
                  helperText={errorMessageSlug}
                  error={errorInSlug}
                  onBlur={(e) => verifySlugValue(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="mt-4 px-3">
                <TextField
                  fullWidth
                  name="schedule"
                  label="Tiempo"
                  type="text"
                  variant="outlined"
                  select
                >
                  <MenuItem value="0 */1 * * *">
                    1 hora
                  </MenuItem>
                  <MenuItem value="0 */2 * * *">
                    2 horas
                  </MenuItem>
                  <MenuItem value="0 */4 * * *">
                    4 horas
                  </MenuItem>
                  <MenuItem value="0 */8 * * *">
                    8 horas
                  </MenuItem>
                  <MenuItem value="0 */12 * * *">
                    12 horas
                  </MenuItem>
                  <MenuItem value="0 0 * * *">
                    24 horas (1 dia)
                  </MenuItem>
                  <MenuItem value="0 0 */2 * *">
                    48 horas (2 dias)
                  </MenuItem>
                  <MenuItem value="0 0 */3 * *">
                    72 horas (3 dias)
                  </MenuItem>
                  <MenuItem value="0 0 * * 0">
                    1 semana
                  </MenuItem>
                </TextField>
              </div>
            </Grid>
          </Grid>
        </form>
        <div ref={blocklyRef} id="blocklyDiv" style={{height: '480px', width: '100%'}} />
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(CustomCronJob);
