import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import {Link, useHistory} from 'react-router-dom';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {
  Box, Typography, ListItemIcon, Drawer,
} from '@material-ui/core';
// import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import toastr from 'toastr';
import Menu from '@material-ui/core/Menu';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import {
  logout, searchProject, listNotification, updateNotification,
  addSuggestion, addSupport, listProject,
} from 'api-lofty';
// import {logout} from '../api/user.api';
import {deletetoken} from '../actions/auth.actions';
// import logo from '../assets/navbar/logo.png';
import tempImg from '../img/temp_img.png';
import notifImg from '../img/user_notif.png';
import AddSuggestionModal from './AddSuggestionModal';
import AddSupportRequestModal from './AddSupportRequestModal';
import 'toastr/build/toastr.min.css';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#CFD6E6',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  navbarUsername: {
    color: 'black',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '16px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  navbarEmail: {
    color: '#A1AEB7',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '16px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  containerNotifications: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#292151',
    marginTop: '3%',
  },
  notificationSender: {
    marginLeft: '10px',
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '12px',
    [theme.breakpoints.down('lg')]: {fontSize: '10px'},
    [theme.breakpoints.down('md')]: {fontSize: '8px'},
  },
  notificationMessage: {
    marginLeft: '10px',
    marginRight: '5px',
    color: '#fff',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '14px',
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
  },
  notificationMenu: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  notificationTitle: {
    marginLeft: '5px',
    fontWeight: 'bold',
    color: '#fff',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '16px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  triangleFigureNotification: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 15px 19px 15px',
    borderColor: 'transparent transparent #292151 transparent',
    position: 'absolute',
    top: 1,
    right: '40px',
    marginTop: '-7px',
    [theme.breakpoints.down('lg')]: {borderWidth: '0 12px 19px 12px', right: '48px'},
    [theme.breakpoints.down('md')]: {borderWidth: '0 9px 19px 9px', right: '53px'},
  },
  drawerPaper: {
    width: 240,
    backgroundColor: '#144999',
  },
}));

const DashboardNavbar = ({
  token, deletetokenApp, user, props,
  handleTutorial,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorNotification, setAnchorNotification] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isNotificationOpen = Boolean(anchorNotification);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [notificationsList, setNotificationsList] = React.useState([]);
  const [newNotifications, setNewNotifications] = React.useState([]);
  const [countNotifications, setCountNotifications] = React.useState(0);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openAddSuggestion, setOpenAddSuggestion] = React.useState(false);
  const [openAddSupport, setOpenAddSupport] = React.useState(false);
  const [userProject, setUserProject] = React.useState([]);

  // const [searchString, setSearchString] = React.useState("");
  // const [countLimit, setCountLimit] = React.useState(5);
  const countLimit = 5;
  const [resData, setResData] = React.useState([{name: 'no data'}]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const openSupportModal = async () => {
    const dataRes = await listProject({token});
    const retVal = [];
    dataRes.projects.map((index) => retVal.push(index));
    setUserProject(dataRes.projects);
    setOpenAddSupport(true);
  };

  const fetchNotifications = async () => {
    const res = await listNotification({token});
    if (res.data) {
      const toFilter = res.data;
      const filteredNotifications = toFilter.filter((key) => !key.wasVisible);
      setNewNotifications(filteredNotifications);
      // console.log(filteredNotifications);
      setCountNotifications(filteredNotifications.length);
    }
    setNotificationsList(res.data);
  };

  const updateStatusPerNotification = async (value) => {
    const wasVisible = true;
    await updateNotification({token, _id: value._id, wasVisible});
  };

  const handleSetNotificationsStatuses = () => {
    try {
      newNotifications.forEach((element) => updateStatusPerNotification(element));
      setCountNotifications(0);
    } catch (error) {
      //
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuOpen = (event) => {
    handleSetNotificationsStatuses();
    setAnchorNotification(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleNotificationClose = () => {
    setAnchorNotification(null);
  };

  const handleLogout = async () => {
    try {
      await logout({token});
      setAnchorEl(null);
      handleMobileMenuClose();
      deletetokenApp();
    } catch (error) {
      if (error.info) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.info,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Lo sentimos no se pudo cerrar sesion',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function emailValue(temporalVal) {
    const temp = temporalVal;
    let retVal;
    if (temp.length < 20) {
      retVal = temp;
    } else {
      let res = temp.substr(0, 19);
      res += '...';
      retVal = res;
    }
    return retVal;
  }

  const handleSearchProject = async (e) => {
    const searchString = e;
    try {
      const response = await searchProject({token, searchString, countLimit});
      // console.log(response);
      setResData(response.data);
    } catch (error) {
      //
    }
  };

  const handleSelectProject = (e) => {
    // console.log(e);
    if (e !== undefined && e !== null && e !== 'no data') {
      const rv = resData.find((searcher) => searcher.name === e);
      history.push(`/dashboard/project/${rv._id}`);
      window.location.reload();
    }
  };

  const handleSaveSuggestion = async (e) => {
    e.preventDefault();
    // console.log(user);
    try {
      await addSuggestion({
        token,
        title: e.target.title.value,
        description: e.target.description.value,
        sector: e.target.sector.value,
        userInfo: user._id,
      });
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      setOpenAddSuggestion(false);
      toastr.clear();
      setTimeout(() => toastr.success('Se agrego la sugerencia'), 300);
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos no se pudo agregar la sugerencia'), 300);
      }
    }
  };

  const handleSaveSupportRequest = async (e) => {
    e.preventDefault(e.target.sectionPerSector.value);
    let valRetSection = '';
    if (e.target.sector.value === 'deploy') {
      valRetSection = 'connection';
    } else {
      valRetSection = e.target.sectionPerSector.value;
    }
    try {
      await addSupport({
        token,
        title: e.target.title.value,
        description: e.target.description.value,
        sector: e.target.sector.value,
        sectionPerSector: valRetSection,
        userInfo: user._id,
        idProject: e.target.idProject.value,
      });
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      setOpenAddSupport(false);
      toastr.clear();
      setTimeout(() => toastr.success('Se agrego la solicitud de soporte'), 300);
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos no se crear la solicitud de soporte'), 300);
      }
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box className={classes.containerMain}>
        <Box
          style={{
            height: '100%', flex: 1, marginTop: '10px', marginLeft: '10px', marginRight: '10px',
          }}
        >
          <Box component="img" src={tempImg} alt="img" style={{width: '40px', height: '40px'}} />
        </Box>
        <Box
          style={{height: '100%', flex: 2}}
        >
          <Box
            component="div"
            style={{
              width: '100%',
              height: '40px',
              marginRight: '10px',
            }}
          >
            <Typography className={classes.navbarUsername}>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
            <Typography className={classes.navbarEmail}>
              {emailValue(user.email)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        borderBottom={1}
        sx={{
          borderColor: '#E8E8E8',
        }}
        style={{
          position: 'relative',
        }}
      >
        <MenuItem>
          <Link className="w-100 h-100" to="/dashboard/profile">
            Perfil y Cuenta
          </Link>
          <Box style={{position: 'absolute', right: 0}}>
            <ListItemIcon>
              <ArrowRightIcon />
            </ListItemIcon>
          </Box>
        </MenuItem>
      </Box>
      <MenuItem onClick={() => setOpenAddSuggestion(true)}>Sugerencia</MenuItem>
      <MenuItem onClick={() => openSupportModal()}>Soporte</MenuItem>
      <Box
        borderTop={1}
        sx={{
          borderColor: '#E8E8E8',
        }}
      >
        <MenuItem onClick={handleLogout}>
          <Typography>
            Cerrar Sesión
          </Typography>
          <Box style={{position: 'absolute', right: 0}}>
            <ListItemIcon>
              <CancelIcon color="error" />
            </ListItemIcon>
          </Box>
        </MenuItem>
      </Box>
    </Menu>
  );

  // NOTIFICATION MENU
  const notificationMenuId = 'primary-search-notification-menu';
  const renderNotification = (
    <Menu
      anchorEl={anchorNotification}
      getContentAnchorEl={null}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      id={notificationMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'left'}}
      open={isNotificationOpen}
      onClose={handleNotificationClose}
      className={classes.notificationMenu}
    >
      <Box
        style={{
          position: 'relative', justifyContent: 'center', alignContent: 'center', alignItems: 'center', display: 'flex',
        }}
      >
        <Box
          component="div"
          className={classes.triangleFigureNotification}
        />
        <Box borderRadius={16} className={classes.containerNotifications}>
          {notificationsList.map((emp) => (
            <Box
              component="div"
              style={{
                width: '100%',
                marginTop: '5px',
                marginBottom: '5px',
                marginRight: '5px',
                marginLeft: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Box
                component="img"
                src={notifImg}
                alt="img"
                style={{
                  width: '30px',
                  height: '30px',
                  marginLeft: '10px',
                  marginBottom: '5px',
                }}
              />
              <Box component="div" style={{marginLeft: '5px', marginBottom: '8px'}}>
                <Typography className={classes.notificationSender}>
                  {`De: ${emp.meta.nameSendBy}`}
                </Typography>
                <Typography className={classes.notificationMessage}>
                  {`Invitacion al proyecto: ${emp.meta.nameProject}`}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleNotificationMenuOpen}>
        <IconButton
          aria-label="show 11 new notifications"
          aria-controls="primary-search-notification-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notificaciones</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Perfil</p>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    fetchNotifications();
  }, [token]);

  return (
    <div className={classes.grow}>
      <Box
        component="div"
        borderBottom={1}
        sx={{
          backgroundColor: '#F3F3F3',
          height: '60px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          p: 1,
          m: 1,
          borderColor: 'text.primary',
        }}
      >
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{
            mr: 2,
            display: {
              xs: 'block',
              sm: 'none',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {props}
        </Drawer>

        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            size="small"
            options={resData.map((option) => option.name)}
            onChange={(e, value) => handleSelectProject(value)}
            renderInput={(values) => (
              <TextField {...values} size="small" placeholder="Buscar.." classes={{root: classes.searchTextField}} variant="outlined" onChange={(e) => handleSearchProject(e.target.value)} />
            )}
          />
        </div>

        {/*
          <Typography className={classes.title} variant="h6" noWrap>
            <Link to="/dashboard">
              <img src={logo} alt="Lofty Apps" style={{width: '70px'}} />
            </Link>
          </Typography>
          */}
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          {
            user?.moneyCredit > 0 && (
              <IconButton aria-label="show credits" color="inherit">
                <span>
                  {`HNL ${user?.moneyCredit.toFixed(2)}`}
                </span>
              </IconButton>
            )
          }
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleTutorial}
            color="inherit"
          >
            <HelpOutlineIcon />
          </IconButton>
          <IconButton
            aria-label="show 17 new notifications"
            color="inherit"
            aria-controls={notificationMenuId}
            aria-haspopup="true"
            onClick={handleNotificationMenuOpen}
          >
            <Badge badgeContent={countNotifications} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </div>
        <Box>
          <AddSuggestionModal
            open={openAddSuggestion}
            handleClose={() => setOpenAddSuggestion(false)}
            handleForm={(e) => handleSaveSuggestion(e)}
          />
        </Box>
        <Box>
          <AddSupportRequestModal
            open={openAddSupport}
            projects={userProject}
            handleClose={() => setOpenAddSupport(false)}
            handleForm={(e) => handleSaveSupportRequest(e)}
          />
        </Box>
      </Box>
      {renderMobileMenu}
      {renderMenu}
      {renderNotification}
    </div>
  );
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);
