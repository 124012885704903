import {withStyles, MenuItem} from '@material-ui/core';

export const createStyledMenuItem = (
  normalIcon,
  selectedIcon,
  hoverIcon,
  focusIcon,
) => withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${normalIcon})`,
      width: '25px',
      height: '25px',
      color: '#fff',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${hoverIcon || selectedIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        color: '#3f51b5',
      },
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      paddingLeft: '22px',
      '& .MuiListItemIcon-root': {
        background: `url(${selectedIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        color: '#3f51b5',
      },
    },
    '&:hover$selected': {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
    '&:focus': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      backgroundColor: theme.palette.common.white,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${focusIcon || selectedIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        color: '#3f51b5',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);
