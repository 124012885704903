import React, {useState, useEffect} from 'react';
import {
  Box, IconButton, TextField, MenuItem,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import {listFieldsDatabase, listDataSimulatorDatabaseAdmin} from 'api-lofty';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  fieldSelect: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
    color: '#292151',
  },
  iconButtonStyle: {
    padding: '5px',
  },
}));

const ArrayDatabaseSelector = ({
  selectedFields,
  setSelectedFields,
  relationDatabase,
  token,
  idProject,
  type, // 'cartArray' o 'arrayDatabase'
}) => {
  const classes = useStyles();
  const [availableDocuments, setAvailableDocuments] = useState([]);
  const [labelField, setLabelField] = useState(null);
  const [initialFields, setInitialFields] = useState(null);

  const transformSelectedFields = (fields) => {
    if (!fields || !Array.isArray(fields)) {
      try {
        let parsedFields = [];
        if (typeof fields === 'string') {
          parsedFields = JSON.parse(fields);
        } else if (fields.data) {
          parsedFields = fields.data;
        } else {
          parsedFields = fields;
        }

        if (Array.isArray(parsedFields)) {
          const transformedFields = parsedFields.map((item) => {
            if (type === 'cartArray' && item.cartitem && item.field) {
              return item;
            }
            if (type === 'arrayDatabase' && item.document && item.field) {
              return item;
            }

            if (type === 'cartArray') {
              if (item.cartitem) {
                return {
                  id: Date.now() + Math.random(),
                  field: item.cartitem._id,
                  cartitem: item.cartitem,
                  quantity: item.quantity || 1,
                };
              }
              if (typeof item === 'string' || item._id) {
                const itemId = typeof item === 'string' ? item : item._id;
                const doc = availableDocuments.find((d) => d._id === itemId);
                return {
                  id: Date.now() + Math.random(),
                  field: itemId,
                  cartitem: doc,
                  quantity: 1,
                };
              }
            }
            if (type === 'arrayDatabase') {
              if (item.document) {
                return {
                  id: Date.now() + Math.random(),
                  field: item.document._id,
                  document: item.document,
                };
              }
              if (typeof item === 'string' || item._id) {
                const itemId = typeof item === 'string' ? item : item._id;
                const doc = availableDocuments.find((d) => d._id === itemId);
                return {
                  id: Date.now() + Math.random(),
                  field: itemId,
                  document: doc,
                };
              }
            }
            return item;
          });
          return transformedFields;
        }
      } catch (error) {
        return [];
      }
    }
    return fields;
  };

  useEffect(() => {
    if (selectedFields && !initialFields) {
      setInitialFields(selectedFields);
    }
  }, [selectedFields]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fieldsResponse = await listFieldsDatabase({
          token,
          idDatabase: relationDatabase,
          idProject,
        });

        const labelFieldData = fieldsResponse.data.find((field) => field.isLabel);
        setLabelField(labelFieldData);

        const documentsResponse = await listDataSimulatorDatabaseAdmin({
          token,
          idDatabase: relationDatabase,
          idProject,
        });

        setAvailableDocuments(documentsResponse.data);

        if (initialFields) {
          let fieldsToProcess = initialFields;
          if (typeof initialFields === 'string') {
            try {
              fieldsToProcess = JSON.parse(initialFields);
            } catch (e) {
              fieldsToProcess = [];
            }
          }

          if (fieldsToProcess && Array.isArray(fieldsToProcess) && fieldsToProcess.length > 0) {
            const needsTransformation = fieldsToProcess.some((field) => {
              if (type === 'cartArray') {
                return !field.cartitem || !field.field;
              }
              return !field.document || !field.field;
            });

            if (needsTransformation) {
              const transformedFields = transformSelectedFields(fieldsToProcess);
              if (transformedFields && transformedFields.length > 0) {
                setSelectedFields(transformedFields);
              }
            } else {
              setSelectedFields(fieldsToProcess);
            }
          }
          setInitialFields(null);
        }
      } catch (error) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Error al cargar los datos de la colección relacionada'), 300);
      }
    };

    if (relationDatabase) {
      fetchData();
    }
  }, [relationDatabase, type, initialFields]);

  const handleAddField = () => {
    const newField = {
      id: Date.now(),
      field: '',
    };

    if (type === 'cartArray') {
      newField.quantity = 1;
    }

    setSelectedFields(Array.isArray(selectedFields) ? [...selectedFields, newField] : [newField]);
  };

  const handleRemoveField = (id) => {
    if (!Array.isArray(selectedFields)) return;
    setSelectedFields(selectedFields.filter((field) => field.id !== id));
  };

  const handleFieldChange = (id, value) => {
    if (!Array.isArray(selectedFields)) {
      setSelectedFields([]);
      return;
    }
    const selectedDoc = availableDocuments.find((doc) => doc._id === value);

    const updatedFields = selectedFields.map((field) => {
      if (field.id === id) {
        if (type === 'cartArray') {
          return {
            id: field.id,
            field: value,
            cartitem: selectedDoc,
            quantity: field.quantity || 1,
          };
        }
        return {
          id: field.id,
          field: value,
          document: selectedDoc,
        };
      }
      return field;
    });
    setSelectedFields(updatedFields);
  };

  const safeSelectedFields = Array.isArray(selectedFields) ? selectedFields : [];

  return (
    <Box>
      {safeSelectedFields.map((field) => {
        let currentValue = '';
        if (type === 'cartArray' && field.cartitem) {
          currentValue = field.cartitem._id;
        } else if (type === 'arrayDatabase' && field.document) {
          currentValue = field.document._id;
        } else if (field.field) {
          currentValue = field.field;
        }

        return (
          <Box key={field.id || Date.now() + Math.random()} className={classes.fieldContainer}>
            <TextField
              select
              fullWidth
              className={classes.fieldSelect}
              value={currentValue}
              onChange={(e) => handleFieldChange(field.id, e.target.value)}
              variant="outlined"
              label="Documento"
            >
              {availableDocuments.map((doc) => (
                <MenuItem key={doc._id} value={doc._id}>
                  {labelField ? doc.data[labelField.name] : doc._id}
                </MenuItem>
              ))}
            </TextField>
            {type === 'cartArray' && (
              <TextField
                type="number"
                style={{width: '100px', marginRight: '8px'}}
                label="Cantidad"
                value={field.quantity || 1}
                onChange={(e) => {
                  const updatedFields = selectedFields.map((f) => {
                    if (f.id === field.id) {
                      return {...f, quantity: parseInt(e.target.value, 10) || 1};
                    }
                    return f;
                  });
                  setSelectedFields(updatedFields);
                }}
                inputProps={{min: 1}}
              />
            )}
            <IconButton
              onClick={() => handleRemoveField(field.id)}
              className={classes.iconButtonStyle}
            >
              <DeleteOutlineOutlinedIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
            </IconButton>
          </Box>
        );
      })}
      <IconButton
        onClick={handleAddField}
        className={classes.addButton}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default ArrayDatabaseSelector;
