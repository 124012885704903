/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {Box} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import toastr from 'toastr';
import {requestListBackoffice, requestPostBackoffice, requestPostBucketBackoffice} from 'api-lofty';
import EditorJs from './EditorJs';
import ArrayDatabaseSelector from './ArrayDatabaseSelector';
import 'toastr/build/toastr.min.css';

const useStyles = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const AddFieldDialogBackoffice = ({
  open, onClose, successFunction, errorFunction,
  token, database, fields, backoffice,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [fieldsForm, setFieldsdForm] = useState([]);
  const [errors, setErrors] = useState({});
  const [arrayFields, setArrayFields] = useState({});

  const validateNumericFields = (formData) => {
    const newErrors = {};
    fields.forEach((field) => {
      if (field.type === 'number' && (formData[field.name] === '' || formData[field.name] === null || formData[field.name] === undefined)) {
        newErrors[field.name] = 'Este campo numérico es requerido';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleShowField = async () => {
    const fieldFormShow = [];
    try {
      for (const field of fields) {
        if (field.type === 'relation' || field.type === 'cartArray' || field.type === 'arrayDatabase') {
          const databaseRelation = backoffice.databases.find((database) => (
            database._id === field.relationDatabase
          ));
          if (databaseRelation) {
            const resRelation = await requestListBackoffice({
              token,
              url: `${backoffice.domain}/api/${databaseRelation.name}/list`,
            });
            const fieldLabel = backoffice.fieldDatabases.find((field) => (
              field.idDatabase === databaseRelation._id && field.isLabel
            ));
            fieldFormShow.push({
              ...field,
              ...{
                data: resRelation.data,
                labelFieldRelation: fieldLabel.name,
              },
            });
          }
        } else if (field.type === 'user') {
          const resRelationUser = await requestListBackoffice({
            token,
            url: `${backoffice.domain}/api/user/list`,
          });
          fieldFormShow.push({
            ...field,
            ...{
              data: resRelationUser.data,
            },
          });
        } else {
          fieldFormShow.push(field);
        }
      }
      setFieldsdForm(fieldFormShow);
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('Error al cargar los campos del formulario'), 300);
    }
  };

  const handleArrayFieldChange = (fieldName, value) => {
    setArrayFields({
      ...arrayFields,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    handleShowField();
  }, [open]);
  return (
    <Dialog fullScreen fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar
            {' '}
            {database.label}
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogTitle />
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            const formData = new FormData(e.target);
            if (!validateNumericFields(Object.fromEntries(formData))) {
              setLoading(false);
              return;
            }
            try {
              const doc = {};
              fields.forEach((item) => {
                if (item.type === 'boolean') {
                  doc[item.name] = !!e.target[item.name].checked;
                } else if (item.type === 'number') {
                  doc[item.name] = parseFloat(e.target[item.name].value);
                } else if (item.type === 'date') {
                  doc[item.name] = new Date(e.target[item.name].value);
                } else if (item.type === 'coordinates') {
                  const latitude = parseFloat(e.target[`${item.name}_latitude`].value);
                  const longitude = parseFloat(e.target[`${item.name}_longitude`].value);
                  doc[item.name] = JSON.stringify({latitude, longitude});
                } else if (item.type === 'cartArray' || item.type === 'arrayDatabase') {
                  const rawValue = arrayFields[item.name] || [];
                  if (item.type === 'cartArray') {
                    doc[item.name] = rawValue.map((item) => ({
                      cartitem: item.cartitem,
                      quantity: item.quantity || 1,
                    }));
                  } else {
                    doc[item.name] = rawValue.map((item) => ({
                      document: item.document,
                    }));
                  }
                } else {
                  doc[item.name] = e.target[item.name].value;
                }
              });
              if (database.isBucket) {
                // eslint-disable-next-line prefer-destructuring
                doc.loftyFile = e.target.loftyFile.files[0];
                await requestPostBucketBackoffice({
                  body: doc, token, url: `${backoffice.domain}/api/${database.name}/create`,
                });
              } else {
                await requestPostBackoffice({
                  body: doc,
                  token,
                  url: `${backoffice.domain}/api/${database.name}/create`,
                });
              }
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          {
            fieldsForm.map((field) => {
              if (field.type === 'string') {
                const valueString = backoffice.valueStringField?.filter((item) => (
                  item.idFieldDatabase === field._id
                ));
                if (valueString?.length > 0) {
                  return (
                    <div className="my-4">
                      <TextField
                        fullWidth
                        name={field.name}
                        select
                        type="text"
                        label={field.label}
                        variant="standard"
                        required={field.required}
                      >
                        {
                          valueString.map((item) => (
                            <MenuItem value={item.slug} key={item._id}>
                              {item.label}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  );
                }
                return (
                  <div className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      type="text"
                      label={field.label}
                      variant="standard"
                      required={field.required}
                    />
                  </div>
                );
              }
              if (field.type === 'number') {
                return (
                  <div className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      label={field.label}
                      variant="standard"
                      InputProps={{
                        inputMode: 'decimal',
                      }}
                      required={field.required}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]}
                    />
                  </div>
                );
              }
              if (field.type === 'boolean') {
                return (
                  <FormControl className="my-4">
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name={field.name}
                          color="primary"
                        />
                      )}
                      label={field.label}
                    />
                  </FormControl>
                );
              }
              if (field.type === 'date') {
                return (
                  <div className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      type="date"
                      label={field.label}
                      variant="standard"
                      required={field.required}
                    />
                  </div>
                );
              }
              if (field.type === 'relation') {
                return (
                  <div className="my-4">
                    <TextField
                      select
                      fullWidth
                      name={field.name}
                      label={field.label}
                      variant="standard"
                      required={field.required}
                    >
                      {
                        field.data.map((item) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item[field.labelFieldRelation]}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </div>
                );
              }
              if (field.type === 'user') {
                return (
                  <div className="my-4">
                    <TextField
                      select
                      fullWidth
                      name={field.name}
                      label={field.label}
                      variant="standard"
                      required={field.required}
                    >
                      {
                        field.data.map((item) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item.loftyEmail}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </div>
                );
              }
              if (field.type === 'coordinates') {
                return (
                  <Box component="div" className="my-4">
                    <Box
                      component="div"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '300px',
                      }}
                    >
                      <Box component="label" style={{fontSize: '16px', color: 'gray', fontFamily: 'Segoe UI'}}>
                        {field.label}
                      </Box>
                      <TextField
                        fullWidth
                        name={`${field.name}_latitude`}
                        type="number"
                        inputProps={{
                          min: -90,
                          max: 90,
                          step: 0.0001,
                        }}
                        label="Latitude"
                        variant="standard"
                        required={field.required}
                      />
                      <TextField
                        fullWidth
                        name={`${field.name}_longitude`}
                        type="number"
                        inputProps={{
                          min: -180,
                          max: 180,
                          step: 0.0001,
                        }}
                        label="Longitude"
                        variant="standard"
                        required={field.required}
                      />
                    </Box>
                  </Box>
                );
              }
              if (field.type === 'textrich') {
                return (
                  <div>
                    <EditorJs
                      label={field.label}
                      name={field.name}
                      token={token}
                      backoffice={backoffice}
                    />
                  </div>
                );
              }
              if (field.type === 'cartArray' || field.type === 'arrayDatabase') {
                return (
                  <div key={field._id} className="my-4">
                    <Box component="div" style={{marginBottom: '10px'}}>
                      <label
                        htmlFor={`array-selector-${field.name}`}
                        style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}
                      >
                        {field.label}
                      </label>
                    </Box>
                    <ArrayDatabaseSelector
                      id={`array-selector-${field.name}`}
                      selectedFields={arrayFields[field.name] || []}
                      setSelectedFields={(value) => handleArrayFieldChange(field.name, value)}
                      relationDatabase={field.relationDatabase}
                      token={token}
                      idProject={backoffice.project._id}
                      type={field.type}
                    />
                  </div>
                );
              }
              return <></>;
            })
          }
          {
            database.isBucket && (
              <div className="my-4">
                <input
                  name="loftyFile"
                  className="w-100"
                  type="file"
                  required
                />
              </div>
            )
          }
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddFieldDialogBackoffice;
