/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {listUserProject} from 'api-lofty';
import {
  Grid, IconButton,
  ListItemIcon, Typography,
  MenuList, Box, Hidden,
} from '@material-ui/core';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import Layout from './Dashboard';
import SidebarItem from '../components/Sidebar/SidebarItem';
import {createStyledMenuItem} from '../components/Sidebar/menuItemFactory';
import {useSidebarStyles} from '../components/Sidebar/sidebarStyles';
import imgLogo from '../img/dashhboard/logo-w_1.png';
import imgLogoCollapsed from '../img/Logo-07.png';
import exitImg from '../img/icons/salir.svg';
import imgActivity1 from '../img/icons/actividad_w.svg';
import imgActivity2 from '../img/icons/actividad_b.svg';
import imgField1 from '../img/icons/campos_w.svg';
import imgField2 from '../img/icons/campos_b.svg';
import imgServices1 from '../img/icons/servicios_w.svg';
import imgServices2 from '../img/icons/servicios_b.svg';
import imgDoc1 from '../img/icons/documentacion_w.svg';
import imgDoc2 from '../img/icons/documentacion_b.svg';
import imgConfig1 from '../img/icons/configuracion_w.svg';
import imgConfig2 from '../img/icons/configuracion_b.svg';
// import imgPermission1 from '../img/icons/permisos_w.svg';
// import imgPermission2 from '../img/icons/permisos_b.svg';
import imgData1 from '../img/icons/datos_w.svg';
import imgData2 from '../img/icons/datos_b.svg';
import 'toastr/build/toastr.min.css';

const StyledMenuField = createStyledMenuItem(imgField1, imgField2);
const StyledMenuServices = createStyledMenuItem(imgServices1, imgServices2);
const StyledMenuDoc = createStyledMenuItem(imgDoc1, imgDoc2);
// const StyledMenuPermission = withStyles((theme) => ({
//   root: {
//     color: '#fff',
//     '& .MuiListItemIcon-root': {
//       background: `url(${imgPermission1})`,
//       width: '20px',
//       height: '20px',
//       backgroundSize: 'contain',
//       backgroundRepeat: 'no-repeat',
//     },
//     '&:hover': {
//       backgroundColor: theme.palette.common.white,
//       borderTopLeftRadius: 12,
//       borderBottomLeftRadius: 12,
//       color: 'black',
//       '& .MuiListItemIcon-root': {
//         background: `url(${imgPermission2})`,
//         width: '17px !important',
//         height: '17px !important',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat',
//       },
//     },
//     '&$selected': {
//       backgroundColor: theme.palette.common.white,
//       color: 'black',
//       borderTopLeftRadius: 12,
//       borderBottomLeftRadius: 12,
//       '& .MuiListItemIcon-root': {
//         background: `url(${imgPermission2})`,
//         width: '17px !important',
//         height: '17px !important',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat',
//       },
//     },

//     [theme.breakpoints.down('lg')]: {
//       '& .MuiListItemIcon-root': {
//         width: '17px',
//         height: '17px',
//       },
//     },

//     [theme.breakpoints.down('md')]: {
//       fontSize: '12px',
//     },
//   },
//   selected: {},
// }))(MenuItem);
const StyledMenuConfig = createStyledMenuItem(imgConfig1, imgConfig2);
const StyledMenuData = createStyledMenuItem(imgData1, imgData2);
const StyledMenuActivity = createStyledMenuItem(imgActivity1, imgActivity2);

const PrincipalDashboard = ({
  children, history, idProject,
  idDatabase, token,
}) => {
  // const [validAuth, setValidAuth] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const classes = useSidebarStyles();

  useEffect(async () => {
    try {
      const res = await listUserProject({idProject, token});
      if (res.data.length > 0) {
        // setValidAuth(true);
      }
    } catch (error) {
      //
      // setValidAuth(false);
    }
  }, []);

  const DrawerMenu = (
    <Box>
      <Box
        className={`
          ${classes.drawerContainer}
          ${isCollapsed ? classes.drawerCollapsed : classes.drawerExpanded}
        `}
      >
        <Hidden smDown>
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={classes.toggleButton}
            size="small"
          >
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Hidden>

        <Link to="/dashboard" style={{marginLeft: isCollapsed ? '-10px' : 0}}>
          <Box
            component="img"
            src={isCollapsed ? imgLogoCollapsed : imgLogo}
            alt="logo"
            style={{
              zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
            }}
          />
        </Link>
        <MenuList autoFocusItem className={classes.menuListContainer}>
          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Campos">
            <StyledMenuField
              selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}`}
              onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Campos
              </Typography>
            </StyledMenuField>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Servicios">
            <StyledMenuServices
              selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/endpoints`}
              onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/endpoints`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Servicios
              </Typography>
            </StyledMenuServices>
          </SidebarItem>
          {/* {
                validAuth && (
                  <StyledMenuPermission
                    selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/permission`}
                    onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/permission`)}
                  >
                    <ListItemIcon />
                    <Typography className={classes.fontItemsStyle}>
                      Permisos
                    </Typography>
                  </StyledMenuPermission>
                )
              } */}
          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Documentación">
            <StyledMenuDoc
              selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/documentation`}
              onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/documentation`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Documentación
              </Typography>
            </StyledMenuDoc>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Datos">
            <StyledMenuData
              selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/data`}
              onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/data`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Datos
              </Typography>
            </StyledMenuData>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Actividad">
            <StyledMenuActivity
              selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/activities`}
              onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/activities`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Actividad
              </Typography>
            </StyledMenuActivity>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Configuraciones">
            <StyledMenuConfig
              selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/bucketconfig`}
              onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/bucketconfig`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Configuraciones
              </Typography>
            </StyledMenuConfig>
          </SidebarItem>
        </MenuList>
        <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Salir de Colección de Datos">
          <Box
            component="div"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              alignItems: 'flex-end',
              minHeight: '100px',
            }}
            className={isCollapsed ? classes.menuItemExitCollapsed : ''}
          >
            <Link to={`/dashboard/project/${idProject}/database`}>
              <Box
                component="img"
                src={exitImg}
                alt="logo"
                className={classes.exitIconStyle}
              />
            </Link>
            <Typography className={classes.exitItemTypo}>
              Salir de Colección de Datos
            </Typography>
          </Box>
        </SidebarItem>
      </Box>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Grid container spacing={0} style={{width: '100%', height: '100%'}}>
        <Hidden smDown>
          <Grid
            item
            style={{
              position: 'relative',
              height: '100%',
              width: isCollapsed ? 80 : 240,
              transition: 'width 0.3s ease',
            }}
          >
            {DrawerMenu}
          </Grid>
        </Hidden>
        <Grid className={classes.contentMaxWidth} item xs>
          <Layout data={DrawerMenu}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(PrincipalDashboard);
