/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import toastr from 'toastr';

// Material UI Components
import {Box} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

// API and Utils
import {listValueStringField} from 'api-lofty';
import {typeFormField} from '../util/typesFields';

// Components
import ArrayDatabaseSelector from './ArrayDatabaseSelector';
import EditorJs from './EditorJs';

const useStyles = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const handleLabelRelation = (data, fields) => {
  let label = '';
  fields.forEach((field) => {
    if (field.isLabel) {
      label += `${data[field.name]}`;
    }
  });
  return label;
};

const handleDefaultData = (docUpdate, field) => {
  if (docUpdate) {
    return docUpdate[field];
  }
  return '';
};

const handleDefaultDateData = (docUpdate, field) => {
  if (docUpdate) {
    return moment(docUpdate[field]).format('YYYY-MM-DD');
  }
  return '';
};

const handleDefaultCoordinate = (docUpdate, field, type) => {
  if (!docUpdate || !field) return 0;

  const coordinates = docUpdate[field.name];

  if (!coordinates) return 0;

  // Si es un objeto con latitude/longitude
  if (typeof coordinates === 'object' && coordinates !== null) {
    return type === 'latitude' ? coordinates.latitude : coordinates.longitude;
  }

  // Si es un string en formato [Latitude: X, Longitude: Y]
  if (typeof coordinates === 'string' && coordinates.includes('Latitude')) {
    const latitudeMatch = coordinates.match(/Latitude:\s*([-0-9.e]+)/);
    const longitudeMatch = coordinates.match(/Longitude:\s*([-0-9.e]+)/);

    if (type === 'latitude' && latitudeMatch) {
      return latitudeMatch[1];
    }
    if (type === 'longitude' && longitudeMatch) {
      return longitudeMatch[1];
    }
  }

  return 0;
};

const BucketDataForm = ({
  open, onClose, loading, handleSubmit, fields, relation, docUpdate, arrayTestUser,
  idProject, token,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState();
  const [valueString, setValueString] = useState([]);
  const [selectedArrayFields, setSelectedArrayFields] = useState({});

  const handleFileChange = (event) => {
    if (!event.target.files) {
      return;
    }
    setFile(event.target.files[0]);
  };

  const handleValueString = async () => {
    try {
      const newValueString = [];
      for (const field of fields) {
        if (field.type === 'string') {
          const res = await listValueStringField({
            idFieldDatabase: field._id, idProject, token,
          });
          newValueString.push({
            field,
            valueString: res.data,
          });
        }
      }
      setValueString(newValueString);
    } catch (error) {
      toastr.error('Error al obtener los valores permitidos de los campos');
    }
  };

  useEffect(() => {
    handleValueString();
  }, []);

  useEffect(() => {
    // Initialize selectedArrayFields with existing data if editing
    if (docUpdate) {
      const arrayFields = {};
      fields.forEach((field) => {
        if (field.type === 'cartArray' || field.type === 'arrayDatabase') {
          arrayFields[field.name] = docUpdate[field.name] || [];
        }
      });
      setSelectedArrayFields(arrayFields);
    }
  }, [docUpdate, fields]);

  const handleClose = () => {
    if (onClose) {
      setSelectedArrayFields({});
      setValueString([]);
      setFile(undefined);
      onClose();
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            {`${!docUpdate ? 'Agregar' : 'Actualizar'}`}
            {' '}
            Dato
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={handleClose}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.target);
              fields.forEach((field) => {
                if (field.type === 'cartArray' || field.type === 'arrayDatabase') {
                  formData.set(field.name, JSON.stringify(selectedArrayFields[field.name] || []));
                }
              });
              handleSubmit(e, file);
            }}
          >
            {fields.map((field) => {
              if (field.type === 'cartArray' || field.type === 'arrayDatabase') {
                return (
                  <div key={field._id} className="my-4">
                    <Box component="div" style={{marginBottom: '10px'}}>
                      <label
                        htmlFor={`array-selector-${field.name}`}
                        style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}
                      >
                        {field.label}
                      </label>
                    </Box>
                    <ArrayDatabaseSelector
                      id={`array-selector-${field.name}`}
                      selectedFields={selectedArrayFields[field.name] || []}
                      setSelectedFields={(fields) => setSelectedArrayFields({
                        ...selectedArrayFields,
                        [field.name]: fields,
                      })}
                      relationDatabase={field.relationDatabase}
                      token={token}
                      idProject={idProject}
                      type={field.type}
                    />
                    <input
                      type="hidden"
                      name={field.name}
                      value={JSON.stringify(selectedArrayFields[field.name] || [])}
                    />
                  </div>
                );
              }
              if (field.type === 'boolean') {
                return (
                  <FormControl key={field._id} className="my-4">
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name={field.name}
                          color="primary"
                        />
                      )}
                      label={field.label}
                      defaultChecked={handleDefaultData(docUpdate, field.name)}
                    />
                  </FormControl>
                );
              }
              if (field.type === 'relation') {
                return (
                  <div key={field._id} className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      select
                      label={field.label}
                      variant="standard"
                      defaultValue={handleDefaultData(docUpdate, field.name)}
                    >
                      {
                        relation[field.name]?.data?.map((data) => (
                          <MenuItem key={data._id} value={data._id}>
                            {handleLabelRelation(data.data, relation[field.name].fields)}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </div>
                );
              }
              if (field.type === 'date') {
                return (
                  <div key={field._id} className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      type={typeFormField(field.type)}
                      label={field.label}
                      variant="standard"
                      defaultValue={handleDefaultDateData(docUpdate, field.name)}
                    />
                  </div>
                );
              }
              if (field.type === 'user') {
                return (
                  <div key={field._id} className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      select
                      label={field.label}
                      variant="standard"
                      defaultValue={handleDefaultData(docUpdate, field.name)}
                    >
                      {
                        arrayTestUser?.map((data) => (
                          <MenuItem key={data._id} value={data._id}>
                            {data.data.loftyEmail}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </div>
                );
              }
              if (field.type === 'hour') {
                return (
                  <Box component="div" className="my-4">
                    <Box
                      component="div"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100px',
                      }}
                    >
                      <Box component="label" style={{fontSize: '16px', color: 'gray', fontFamily: 'Segoe UI'}}>
                        {field.label}
                      </Box>
                      <Box component="input" type="time" name={field.name} label={field.label} />
                    </Box>
                  </Box>
                );
              }
              if (field.type === 'coordinates') {
                return (
                  <Box component="div" className="my-4">
                    <Box
                      component="div"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '300px',
                      }}
                    >
                      <Box component="label" style={{fontSize: '16px', color: 'gray', fontFamily: 'Segoe UI'}}>
                        {field.label}
                      </Box>
                      <TextField
                        fullWidth
                        name={`${field.name}_latitude`}
                        type="number"
                        inputProps={{
                          min: -90,
                          max: 90,
                          step: 0.0001,
                        }}
                        label="Latitude"
                        variant="standard"
                        defaultValue={handleDefaultCoordinate(docUpdate, field, 'latitude')}
                      />
                      <TextField
                        fullWidth
                        name={`${field.name}_longitude`}
                        type="number"
                        inputProps={{
                          min: -180,
                          max: 180,
                          step: 0.0001,
                        }}
                        label="Longitude"
                        variant="standard"
                        defaultValue={handleDefaultCoordinate(docUpdate, field, 'longitude')}
                      />
                    </Box>
                  </Box>
                );
              }
              if (field.type === 'textrich') {
                return (
                  <EditorJs
                    defaultValue={handleDefaultData(docUpdate, field.name)}
                    label={field.label}
                    name={field.name}
                    idProject={idProject}
                    token={token}
                  />
                );
              }
              if (field.type === 'string') {
                const values = valueString.find((item) => item.field._id === field._id);
                if (values && values.valueString?.length > 0) {
                  return (
                    <>
                      <div key={field._id} className="my-4">
                        <TextField
                          fullWidth
                          name={field.name}
                          select
                          type="text"
                          label={field.label}
                          variant="standard"
                          defaultValue={handleDefaultData(docUpdate, field.name)}
                        >
                          {
                          values.valueString.map((item) => (
                            <MenuItem key={item._id} value={item.slug}>
                              {item.label}
                            </MenuItem>
                          ))
                        }
                        </TextField>
                      </div>
                    </>
                  );
                }
                return (
                  <>
                    <div key={field._id} className="my-4">
                      <TextField
                        fullWidth
                        name={field.name}
                        type="text"
                        label={field.label}
                        variant="standard"
                        defaultValue={handleDefaultData(docUpdate, field.name)}
                      />
                    </div>
                  </>
                );
              }
              return (
                <div key={field._id} className="my-4">
                  <TextField
                    fullWidth
                    name={field.name}
                    type={typeFormField(field.type)}
                    label={field.label}
                    variant="standard"
                    defaultValue={handleDefaultData(docUpdate, field.name)}
                  />
                </div>
              );
            })}
            <div key="file" className="my-4">
              <Button
                variant="contained"
                component="label"
                onChange={handleFileChange}
              >
                {file ? `${file.name}` : 'Upload File'}
                <input
                  type="file"
                  hidden
                  required
                />
              </Button>
            </div>
            <div className="text-center my-4">
              <button
                style={{
                  background: '#201549',
                }}
                type="submit"
                className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                disabled={loading}
              >
                {
                loading ? (
                  <>
                    Procesando ...
                  </>
                ) : (
                  <>
                    Guardar
                  </>
                )
              }
              </button>
            </div>
          </form>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default BucketDataForm;
