import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Grid, IconButton,
  ListItemIcon, Typography,
  MenuList, Box, Hidden,
  Collapse,
} from '@material-ui/core';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import Layout from './Dashboard';
import SidebarItem from '../components/Sidebar/SidebarItem';
import {createStyledMenuItem} from '../components/Sidebar/menuItemFactory';
import {useSidebarStyles} from '../components/Sidebar/sidebarStyles';
import imgLogo from '../img/dashhboard/logo-w_1.png';
import imgLogoCollapsed from '../img/Logo-07.png';
import exitImg from '../img/icons/salir.svg';

const StyledMenuItem = createStyledMenuItem();

const BackofficeDashboard = ({
  children, history, backoffice, idProject,
}) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const classes = useSidebarStyles();

  const drawerMenu = (
    <Box>
      <Box
        className={`
          ${classes.drawerContainer}
          ${isCollapsed ? classes.drawerCollapsed : classes.drawerExpanded}
        `}
      >
        <Hidden smDown>
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={classes.toggleButton}
            size="small"
          >
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Hidden>

        <Box display="flex" alignItems="center" justifyContent="space-between" padding="10px">
          <Link to={`/dashboard/project/${idProject}`} style={{marginLeft: isCollapsed ? '-10px' : 0}}>
            <Box
              component="img"
              src={isCollapsed ? imgLogoCollapsed : imgLogo}
              alt="logo"
              style={{
                zIndex: 1,
                height: '90px',
              }}
            />
          </Link>
          {/* <CloseIcon
            style={{
              color: '#fff',
              cursor: 'pointer',
              marginRight: '10px',
              fontSize: '30px',
            }}
            onClick={() => history.push(`/dashboard/project/${idProject}`)}
          /> */}
        </Box>
        <MenuList autoFocusItem className={classes.menuListContainer}>
          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Dashboard">
            <StyledMenuItem
              selected={window.location.pathname === `/dashboard/project/${idProject}/backoffice`}
              onClick={() => history.push(`/dashboard/project/${idProject}/backoffice`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <Typography className={classes.fontItemsStyle}>
                Dashboard
              </Typography>
            </StyledMenuItem>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Usuarios">
            <StyledMenuItem
              selected={window.location.pathname === `/dashboard/project/${idProject}/backoffice/user`}
              onClick={() => history.push(`/dashboard/project/${idProject}/backoffice/user`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <Typography className={classes.fontItemsStyle}>
                Usuarios
              </Typography>
            </StyledMenuItem>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Notificaciones">
            <StyledMenuItem
              selected={window.location.pathname === `/dashboard/project/${idProject}/backoffice/notifications`}
              onClick={() => history.push(`/dashboard/project/${idProject}/backoffice/notifications`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <Typography className={classes.fontItemsStyle}>
                Notificaciones
              </Typography>
            </StyledMenuItem>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Colecciones">
            <StyledMenuItem
              selected={openSubMenu}
              onClick={() => setOpenSubMenu(!openSubMenu)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <Typography className={classes.fontItemsStyle}>
                Colecciones
              </Typography>
              <Box
                style={{
                  width: '30%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {openSubMenu ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </Box>
            </StyledMenuItem>
          </SidebarItem>

          <Collapse in={openSubMenu} style={{backgroundColor: '#083567', padding: '0px 10px'}}>
            {
            backoffice.databases.map((database) => (
              <SidebarItem isCollapsed={isCollapsed} tooltipTitle={database.label}>
                <StyledMenuItem
                  selected={window.location.pathname === `/dashboard/project/${idProject}/backoffice/${database.name}`}
                  onClick={() => history.push(`/dashboard/project/${idProject}/backoffice/${database.name}`)}
                  className={isCollapsed ? classes.menuItemCollapsed : ''}
                >
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <Typography className={classes.fontItemsStyle} style={{fontSize: '16px', paddingLeft: '20px'}}>
                    {database.label}
                  </Typography>
                </StyledMenuItem>
              </SidebarItem>
            ))
            }
          </Collapse>
        </MenuList>
        <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Salir de Backoffice">
          <Box
            component="div"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              alignItems: 'flex-end',
              minHeight: '100px',
            }}
            className={isCollapsed ? classes.menuItemExitCollapsed : ''}
          >
            <Link to={`/dashboard/project/${idProject}`}>
              <Box
                component="img"
                src={exitImg}
                alt="logo"
                className={classes.exitIconStyle}
              />
            </Link>
            <Typography className={classes.exitItemTypo}>
              Salir de Backoffice
            </Typography>
          </Box>
        </SidebarItem>
      </Box>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Grid container spacing={0} style={{width: '100%', height: '100%'}}>
        <Hidden smDown>
          <Grid
            item
            style={{
              position: 'relative',
              height: '100%',
              width: isCollapsed ? 80 : 240,
              transition: 'width 0.3s ease',
            }}
          >
            {drawerMenu}
          </Grid>
        </Hidden>
        <Grid className={classes.contentMaxWidth} item xs>
          <Layout data={drawerMenu}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  backoffice: state.backoffice.data,
});

export default connect(mapStateToProps)(BackofficeDashboard);
