import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
  Grid, IconButton,
  ListItemIcon, Typography,
  MenuList, Box, Hidden,
} from '@material-ui/core';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import {getLoggedUser} from 'api-lofty';
import Layout from './Dashboard';
import SidebarItem from '../components/Sidebar/SidebarItem';
import {createStyledMenuItem} from '../components/Sidebar/menuItemFactory';
import {useSidebarStyles} from '../components/Sidebar/sidebarStyles';
import imgLogo from '../img/dashhboard/logo-w_1.png';
import imgLogoCollapsed from '../img/Logo-07.png';
import imgInit1 from '../img/icons/plantillas_w.svg';
import imgInit2 from '../img/icons/plantillas_b.svg';
import imgInv1 from '../img/icons/invitar_w.svg';
import imgInv2 from '../img/icons/invitar_b.svg';
import imgProf1 from '../img/icons/perfil_w.svg';
import imgProf2 from '../img/icons/perfil_b.svg';
// import imgPay1 from '../img/icons/creditcard_w.svg';
// import imgPay2 from '../img/icons/creditcard_b.svg';
import imgActivity1 from '../img/icons/actividad_w.svg';
import imgActivity2 from '../img/icons/actividad_b.svg';
import imgProject1 from '../img/icons/proyecto_w.svg';
import imgProject2 from '../img/icons/proyecto_b.svg';

const StyledMenuProject = createStyledMenuItem(imgProject1, imgProject2);
const StyledMenuPlantilla = createStyledMenuItem(imgInit1, imgInit2);
const StyledMenuInvite = createStyledMenuItem(imgInv1, imgInv2);
const StyledMenuProf = createStyledMenuItem(imgProf1, imgProf2);
// const StyledMenuPayment = withStyles((theme) => ({
//   root: {
//     color: '#fff',
//     '& .MuiListItemIcon-root': {
//       background: `url(${imgPay1})`,
//       width: '20px',
//       height: '20px',
//       backgroundSize: 'contain',
//       backgroundRepeat: 'no-repeat',
//     },
//     '&:hover': {
//       backgroundColor: theme.palette.common.white,
//       borderTopLeftRadius: 12,
//       borderBottomLeftRadius: 12,
//       color: 'black',
//       '& .MuiListItemIcon-root': {
//         background: `url(${imgPay2})`,
//         width: '20px',
//         height: '20px',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat',
//       },
//       '&:focus': {
//         backgroundColor: theme.palette.common.white,
//       },
//     },
//     '&:focus': {
//       borderTopLeftRadius: 12,
//       borderBottomLeftRadius: 12,
//       backgroundColor: theme.palette.common.white,
//       color: 'black',
//       '& .MuiListItemIcon-root': {
//         background: `url(${imgPay2})`,
//         width: '20px',
//         height: '20px',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat',
//       },
//     },
//     [theme.breakpoints.down('lg')]: {
//       '& .MuiListItemIcon-root': {
//         width: '17px',
//         height: '17px',
//       },
//       '&:hover': {
//         '& .MuiListItemIcon-root': {
//           width: '17px !important',
//           height: '17px !important',
//         },
//       },
//       '&:focus': {
//         '& .MuiListItemIcon-root': {
//           width: '17px !important',
//           height: '17px !important',
//         },
//       },
//     },
//   },
// }))(MenuItem);
const StyledMenuActivity = createStyledMenuItem(imgActivity1, imgActivity2);

const PrincipalDashboard = ({
  children, history, handleTutorial, token,
}) => {
  const [isActiveUser, setIsActiveUser] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const classes = useSidebarStyles();

  useEffect(async () => {
    try {
      const getUserInfo = await getLoggedUser({token});
      if (getUserInfo.user.verified) {
        setIsActiveUser(true);
      }
    } catch (error) {
      //
    }
    // getLoggedUser({token}).then((res) => {
    //   console.log(res);
    //   if (res.user.verified) {
    //     console.log(res.user.verified);
    //     setIsActiveUser(true);
    //   }
    // }).catch((error) => {
    //   console.log(error);
    // });
  }, []);
  const limitedDrawer = (
    <Box>
      <Box className={`${classes.drawerContainer} ${isCollapsed ? classes.drawerCollapsed : classes.drawerExpanded}`}>
        <Hidden smDown>
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={classes.toggleButton}
            size="small"
          >
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Hidden>

        <Link to="/dashboard" style={{marginLeft: isCollapsed ? '-10px' : 0}}>
          <Box
            component="img"
            alt="logo"
            style={{
              zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
            }}
          />
        </Link>

        <MenuList
          autoFocusItem
          style={{
            marginLeft: '5%',
          }}
        >
          <StyledMenuProf
            selected={window.location.pathname === '/dashboard/profile'}
            onClick={() => history.push('/dashboard/profile')}
            id="profile_platform"
            className={isCollapsed ? classes.menuItemCollapsed : ''}
          >
            <ListItemIcon />
            <Typography variant="inherit" className={classes.menuItemTypo}>
              Mi Perfil
            </Typography>
          </StyledMenuProf>
        </MenuList>
      </Box>
    </Box>
  );

  const drawerMenu = (
    <Box>
      <Box className={`${classes.drawerContainer} ${isCollapsed ? classes.drawerCollapsed : classes.drawerExpanded}`}>
        <Hidden smDown>
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={classes.toggleButton}
            size="small"
          >
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Hidden>

        <Link to="/dashboard" style={{marginLeft: isCollapsed ? '-10px' : 0}}>
          <Box
            component="img"
            src={isCollapsed ? imgLogoCollapsed : imgLogo}
            alt="logo"
            style={{
              zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
            }}
          />
        </Link>
        <MenuList autoFocusItem className={classes.menuListContainer}>
          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Proyectos">
            <StyledMenuProject
              selected={window.location.pathname === '/dashboard'}
              onClick={() => history.push('/dashboard')}
              id="projects_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography variant="inherit" className={classes.fontItemsStyle}>
                Proyectos
              </Typography>
            </StyledMenuProject>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Plantillas">
            <StyledMenuPlantilla
              selected={window.location.pathname === '/dashboard/templates'}
              onClick={() => history.push('/dashboard/templates')}
              id="templates_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography variant="inherit" className={classes.fontItemsStyle}>
                Plantillas
              </Typography>
            </StyledMenuPlantilla>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Invitaciones">
            <StyledMenuInvite
              selected={window.location.pathname === '/dashboard/invitations'}
              onClick={() => history.push('/dashboard/invitations')}
              id="invites_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography variant="inherit" className={classes.fontItemsStyle}>
                Invitaciones
              </Typography>
            </StyledMenuInvite>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Mi Perfil">
            <StyledMenuProf
              selected={window.location.pathname === '/dashboard/profile'}
              onClick={() => history.push('/dashboard/profile')}
              id="profile_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography variant="inherit" className={classes.fontItemsStyle}>
                Mi Perfil
              </Typography>
            </StyledMenuProf>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Agendar Reunión">
            <StyledMenuProf
              selected={window.location.pathname === '/dashboard/calendar'}
              onClick={() => history.push('/dashboard/calendar')}
              id="schedule_meeting"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography variant="inherit" className={classes.fontItemsStyle}>
                Agendar Reunión
              </Typography>
            </StyledMenuProf>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Afiliados">
            <StyledMenuProf
              selected={window.location.pathname === '/dashboard/affiliates'}
              onClick={() => history.push('/dashboard/affiliates')}
              id="affiliates_info"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography variant="inherit" className={classes.fontItemsStyle}>
                Afiliados
              </Typography>
            </StyledMenuProf>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Canjear Codigo">
            <StyledMenuInvite
              selected={window.location.pathname === '/dashboard/redeem'}
              onClick={() => history.push('/dashboard/redeem')}
              id="profile_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography variant="inherit" className={classes.fontItemsStyle}>
                Canjear Codigo
              </Typography>
            </StyledMenuInvite>
          </SidebarItem>

          <Hidden xlDown>
            {/* <StyledMenuPayment
              selected={window.location.pathname === '/dashboard/infocredit'}
              onClick={() => history.push('/dashboard/infocredit')}
              id="payment_platform"
            >
              <ListItemIcon />
              <Typography variant="inherit" className={classes.fontItemsStyle}>
                Métodos de Pago
              </Typography>
            </StyledMenuPayment> */}
          </Hidden>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Actividad">
            <StyledMenuActivity
              selected={window.location.pathname === '/dashboard/activities'}
              onClick={() => history.push('/dashboard/activities')}
              id="activity"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography variant="inherit" className={classes.fontItemsStyle}>
                Actividad
              </Typography>
            </StyledMenuActivity>
          </SidebarItem>
        </MenuList>
      </Box>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Grid container spacing={0} style={{width: '100%', height: '100%'}}>
        <Hidden smDown>
          <Grid
            item
            style={{
              position: 'relative',
              height: '100%',
              width: isCollapsed ? 80 : 240,
              transition: 'width 0.3s ease',
            }}
          >
            {isActiveUser ? (
              drawerMenu
            ) : (
              limitedDrawer
            )}
          </Grid>
        </Hidden>
        <Grid className={classes.contentMaxWidth} item xs>
          <Layout data={drawerMenu} toTutorial={handleTutorial}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrincipalDashboard;
