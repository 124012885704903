import React, {useState, useEffect} from 'react';
import {createReactEditorJS} from 'react-editor-js';
import {addAssetsProject} from 'api-lofty';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
/* import LinkTool from '@editorjs/link'; */
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';
import {getBase64} from '../util/base64';

const ReactEditorJS = createReactEditorJS();

// Valor por defecto seguro para el editor
const EMPTY_EDITOR_VALUE = {
  time: new Date().getTime(),
  blocks: [],
};

const EditorJs = ({
  defaultValue, label,
  name, idProject, token,
  backoffice,
}) => {
  const [editorValue, setEditorValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Herramientas del editor
  const EDITOR_JS_TOOLS = {
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
    },
    table: Table,
    list: List,
    image: {
      class: ImageTool,
      config: {
        uploader: {
          uploadByFile: async (file) => {
            try {
              if (!backoffice) {
                const res = await addAssetsProject({
                  idProject,
                  token,
                  files: file,
                });
                return {
                  success: 1,
                  file: {
                    url: res.data[0]?.src,
                  },
                };
              }
              const base64 = await getBase64(file);
              return {
                success: 1,
                file: {
                  url: base64,
                },
              };
            } catch (error) {
              return {
                success: 0,
                error: 'Failed to upload file',
              };
            }
          },
        },
        additionalRequestHeaders: {
          Authorization: token,
        },
      },
    },
  };

  // Inicializar el editor con valores seguros
  useEffect(() => {
    try {
      let initialValue = EMPTY_EDITOR_VALUE;

      if (defaultValue) {
        if (typeof defaultValue === 'string') {
          try {
            const parsed = JSON.parse(defaultValue);
            initialValue = {
              time: new Date().getTime(),
              blocks: Array.isArray(parsed) ? parsed : parsed.blocks || [],
            };
          } catch (e) {
            // Error silencioso al parsear
          }
        } else if (typeof defaultValue === 'object') {
          initialValue = {
            time: new Date().getTime(),
            blocks: Array.isArray(defaultValue) ? defaultValue : defaultValue.blocks || [],
          };
        }
      }

      setEditorValue(initialValue);
      setIsLoading(false);
    } catch (err) {
      setError('Error initializing editor');
      setIsLoading(false);
    }
  }, [defaultValue]);

  const handleEditorChange = async (api) => {
    try {
      const output = await api.saver.save();
      if (output && output.blocks) {
        const valueToStore = JSON.stringify(output.blocks);
        setEditorValue(output);
        const hiddenInput = document.querySelector(`input[name="${name}"]`);
        if (hiddenInput) {
          hiddenInput.value = valueToStore;
        }
      }
    } catch (err) {
      setError('Error saving content');
    }
  };

  if (isLoading) {
    return <div>Cargando editor...</div>;
  }

  if (error) {
    return (
      <div>
        Error:
        {error}
        .
        <button type="button" onClick={() => window.location.reload()}>
          Reintentar
        </button>
      </div>
    );
  }

  const editorId = `editor-${name}`;

  return (
    <div
      className="editor-container"
      style={{
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        padding: '10px',
        marginTop: '8px',
        marginBottom: '8px',
      }}
    >
      <label
        htmlFor={editorId}
        style={{
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '1rem',
          marginBottom: '8px',
          display: 'block',
        }}
      >
        {label}
      </label>
      <div id={editorId}>
        <ReactEditorJS
          defaultValue={editorValue}
          tools={EDITOR_JS_TOOLS}
          onChange={handleEditorChange}
          onReady={() => setIsLoading(false)}
        />
      </div>
      <input
        value={JSON.stringify(editorValue?.blocks || [])}
        type="hidden"
        name={name}
      />
    </div>
  );
};

export default EditorJs;
