/* eslint-disable no-alert */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {listUserProject, getOneProject, listTeamUser} from 'api-lofty';
import {
  Grid, IconButton,
  ListItemIcon, Typography,
  MenuList, Box, Hidden,
} from '@material-ui/core';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
/* import ReceiptIcon from '@material-ui/icons/Receipt'; */
import Layout from './Dashboard';
import AddFieldSlugProject from '../components/AddFieldSlugProject';
import SidebarItem from '../components/Sidebar/SidebarItem';
import {createStyledMenuItem} from '../components/Sidebar/menuItemFactory';
import {useSidebarStyles} from '../components/Sidebar/sidebarStyles';
import imgLogo from '../img/dashhboard/logo-w_1.png';
import imgLogoCollapsed from '../img/Logo-07.png';
import exitImg from '../img/icons/salir.svg';
import imgDashboard1 from '../img/icons/dasboard_w.svg';
import imgDashboard2 from '../img/icons/dasboard_b.svg';
import imgDatabase1 from '../img/icons/basedatos_w.svg';
import imgDatabase2 from '../img/icons/basedatos_b.svg';
import imgPages1 from '../img/icons/paginas_w.svg';
import imgPages2 from '../img/icons/paginas_b.svg';
import imgTeam1 from '../img/icons/equipo_w.svg';
import imgTeam2 from '../img/icons/equipo_b.svg';
import imgUser1 from '../img/icons/usuarios_w.svg';
import imgUser2 from '../img/icons/usuarios_b.svg';
import imgPlugin1 from '../img/icons/plugin_w.svg';
import imgPlugin2 from '../img/icons/plugin_b.svg';
import imgApi1 from '../img/icons/api_w.svg';
import imgApi2 from '../img/icons/api_b.svg';
import imgMulti1 from '../img/icons/multimedia_w.svg';
import imgMulti2 from '../img/icons/multimedia_b.svg';
import imgActivity1 from '../img/icons/actividad_w.svg';
import imgActivity2 from '../img/icons/actividad_b.svg';
import imgConfig1 from '../img/icons/configuracion_w.svg';
import imgConfig2 from '../img/icons/configuracion_b.svg';
import imgDeploy1 from '../img/icons/despliegue_w.svg';
import imgDeploy2 from '../img/icons/despliegue_b.svg';

const StyledMenuDashboard = createStyledMenuItem(imgDashboard1, imgDashboard2);
const StyledMenuDatabase = createStyledMenuItem(imgDatabase1, imgDatabase2);
const StyledMenuPages = createStyledMenuItem(imgPages1, imgPages2);
const StyledMenuTeam = createStyledMenuItem(imgTeam1, imgTeam2);
const StyledMenuUser = createStyledMenuItem(imgUser1, imgUser2);
const StyledMenuPlugin = createStyledMenuItem(imgPlugin1, imgPlugin2);
const StyledMenuApi = createStyledMenuItem(imgApi1, imgApi2);
const StyledMenuMultimedia = createStyledMenuItem(imgMulti1, imgMulti2);
const StyledMenuActivity = createStyledMenuItem(imgActivity1, imgActivity2);
const StyledMenuConfig = createStyledMenuItem(imgConfig1, imgConfig2);
const StyledMenuDeploy = createStyledMenuItem(imgDeploy1, imgDeploy2);

const PrincipalDashboard = ({
  children, history,
  idProject, token, user,
  handleTutorial,
}) => {
  // console.log(projectName); WAIT UNTIL USE
  // eslint-disable-next-line no-unused-vars
  const [isUserProject, setIsUserProject] = useState(false);
  const [belonger, setBelonger] = useState(false);
  const [openSlug, setOpenSlug] = useState(false);
  const [adminUser, setAdminUser] = useState(false);
  const [project, setProject] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(false);
  const classes = useSidebarStyles();

  useEffect(async () => {
    try {
      const resUserProject = await listUserProject({idProject, token});
      const resTeam = await listTeamUser({token});
      resTeam.data.forEach((index) => {
        if (index.dataProject[0]._id === idProject && index.permission === 'admin') {
          setAdminUser(true);
        }
      });
      getOneProject({token, idProject}).then((res) => {
        const {project} = res;
        if (!project.slug) {
          setOpenSlug(true);
        }
        setBelonger(project.userId === user._id);
        setProject(project);
      });
      if (resUserProject.data.length > 0) {
        setIsUserProject(true);
      } else {
        setIsUserProject(false);
      }
    } catch (error) {
      setIsUserProject(false);
    }
  }, []);

  const DrawerMenu = (
    <Box>
      <Box
        className={`
          ${classes.drawerContainer}
          ${isCollapsed ? classes.drawerCollapsed : classes.drawerExpanded}
        `}
      >
        <Hidden smDown>
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={classes.toggleButton}
            size="small"
          >
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Hidden>
        <Link to="/dashboard" style={{marginLeft: isCollapsed ? '-10px' : 0}}>
          <Box
            component="img"
            src={isCollapsed ? imgLogoCollapsed : imgLogo}
            alt="logo"
            style={{
              zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
            }}
          />
        </Link>
        <MenuList autoFocusItem className={classes.menuListContainer}>
          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Dashboard">
            <StyledMenuDashboard
              selected={window.location.pathname === `/dashboard/project/${idProject}`}
              onClick={() => history.push(`/dashboard/project/${idProject}`)}
              id="dashboard_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Dashboard
              </Typography>
            </StyledMenuDashboard>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Colección de Datos">
            <StyledMenuDatabase
              selected={window.location.pathname === `/dashboard/project/${idProject}/database`}
              onClick={() => history.push(`/dashboard/project/${idProject}/database`)}
              id="database_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Colección de Datos
              </Typography>
            </StyledMenuDatabase>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Páginas">
            <StyledMenuPages
              selected={window.location.pathname === `/dashboard/project/${idProject}/page`}
              onClick={() => history.push(`/dashboard/project/${idProject}/page`)}
              id="pages_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Páginas
              </Typography>
            </StyledMenuPages>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Usuarios">
            <StyledMenuUser
              selected={window.location.pathname === `/dashboard/project/${idProject}/users`}
              onClick={() => history.push(`/dashboard/project/${idProject}/users`)}
              id="users_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Usuarios
              </Typography>
            </StyledMenuUser>
          </SidebarItem>

          {
            belonger && (
              <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Equipo">
                <StyledMenuTeam
                  selected={window.location.pathname === `/dashboard/project/${idProject}/team`}
                  onClick={() => history.push(`/dashboard/project/${idProject}/team`)}
                  id="teams_platform"
                  className={isCollapsed ? classes.menuItemCollapsed : ''}
                >
                  <ListItemIcon />
                  <Typography className={classes.fontItemsStyle}>
                    Equipo
                  </Typography>
                </StyledMenuTeam>
              </SidebarItem>
            )
          }

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Plugins">
            <StyledMenuPlugin
              // Commit
              selected={window.location.pathname === `/dashboard/project/${idProject}/plugins` || window.location.pathname === `/dashboard/project/${idProject}/plugins/addplugins`}
              onClick={() => history.push(`/dashboard/project/${idProject}/plugins`)}
              id="plugins_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Plugins
              </Typography>
            </StyledMenuPlugin>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Metodo de Pago">
            <StyledMenuDashboard
              selected={window.location.pathname === `/dashboard/project/${idProject}/methodpayment`}
              onClick={() => history.push(`/dashboard/project/${idProject}/methodpayment`)}
              id="payment_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Metodo de Pago
              </Typography>
            </StyledMenuDashboard>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Cron Jobs">
            <StyledMenuDashboard
              selected={window.location.pathname === `/dashboard/project/${idProject}/cronjobs`}
              onClick={() => history.push(`/dashboard/project/${idProject}/cronjobs`)}
              id="payment_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Cron Jobs
              </Typography>
            </StyledMenuDashboard>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="API Key Test">
            <StyledMenuApi
              selected={window.location.pathname === `/dashboard/project/${idProject}/apikeytesting`}
              onClick={() => history.push(`/dashboard/project/${idProject}/apikeytesting`)}
              id="api_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                API Key Test
              </Typography>
            </StyledMenuApi>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Multimedia">
            <StyledMenuMultimedia
              selected={window.location.pathname === `/dashboard/project/${idProject}/assets`}
              onClick={() => history.push(`/dashboard/project/${idProject}/assets`)}
              id="media_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Multimedia
              </Typography>
            </StyledMenuMultimedia>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Actividad">
            <StyledMenuActivity
              selected={window.location.pathname === `/dashboard/project/${idProject}/activities`}
              onClick={() => history.push(`/dashboard/project/${idProject}/activities`)}
              id="logsproject_platform"
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Actividad
              </Typography>
            </StyledMenuActivity>
          </SidebarItem>

          {
            (belonger || adminUser) && (
              <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Configuración">
                <StyledMenuConfig
                  selected={window.location.pathname === `/dashboard/project/${idProject}/config`}
                  onClick={() => history.push(`/dashboard/project/${idProject}/config`)}
                  id="config_platform"
                  className={isCollapsed ? classes.menuItemCollapsed : ''}
                >
                  <ListItemIcon />
                  <Typography className={classes.fontItemsStyle}>
                    Configuración
                  </Typography>
                </StyledMenuConfig>
              </SidebarItem>
            )
          }
          {
            belonger && (
              <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Historial de Pago">
                <StyledMenuConfig
                  selected={window.location.pathname === `/dashboard/project/${idProject}/logpayment`}
                  onClick={() => history.push(`/dashboard/project/${idProject}/logpayment`)}
                  id="log_payment"
                  className={isCollapsed ? classes.menuItemCollapsed : ''}
                >
                  <ListItemIcon />
                  <Typography className={classes.fontItemsStyle}>
                    Historial de Pago
                  </Typography>
                </StyledMenuConfig>
              </SidebarItem>
            )
          }
          {
            project.deployed ? (
              <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Producción">
                <StyledMenuDeploy
                  selected={window.location.pathname === `/dashboard/project/${idProject}/deployed`}
                  onClick={() => history.push(`/dashboard/project/${idProject}/deployed`)}
                  id="deploy_platform"
                  className={isCollapsed ? classes.menuItemCollapsed : ''}
                >
                  <ListItemIcon />
                  <Typography className={classes.fontItemsStyle}>
                    Producción
                  </Typography>
                </StyledMenuDeploy>
              </SidebarItem>
            ) : <></>
          }
          {
            belonger && (
              <>
                {
                  project.deployed ? (
                    <></>
                  ) : (
                    <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Despliegue">
                      <StyledMenuDeploy
                        selected={window.location.pathname === `/dashboard/project/${idProject}/deployment`}
                        onClick={() => history.push(`/dashboard/project/${idProject}/deployment`)}
                        id="deploy_platform"
                        className={isCollapsed ? classes.menuItemCollapsed : ''}
                      >
                        <ListItemIcon />
                        <Typography className={classes.fontItemsStyle}>
                          Despliegue
                        </Typography>
                      </StyledMenuDeploy>
                    </SidebarItem>
                  )
                }
              </>
            )
          }
        </MenuList>
        <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Salir del Proyecto">
          <Box
            component="div"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              alignItems: 'flex-end',
              minHeight: '100px',
            }}
            className={isCollapsed ? classes.menuItemExitCollapsed : ''}
          >
            <Link to="/dashboard">
              <Box
                component="img"
                src={exitImg}
                alt="logo"
                className={classes.exitIconStyle}
              />
            </Link>
            <Typography className={classes.exitItemTypo}>
              Salir del Proyecto
            </Typography>
          </Box>
        </SidebarItem>
      </Box>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      {/* Validacion para el proyecto */}
      <AddFieldSlugProject
        idProject={idProject}
        token={token}
        open={openSlug}
        successFunction={() => {
          setOpenSlug(false);
        }}
        errorFunction={(error) => {
          if (error.info) {
            alert(error.info);
          } else {
            alert('Lo sentimos no se puedo guardar el dato, por favor contacte con soporte tecnico');
          }
        }}
      />
      <Grid container spacing={0} style={{width: '100%', height: '100%'}}>
        <Hidden smDown>
          <Grid
            item
            style={{
              position: 'relative',
              height: '100%',
              width: isCollapsed ? 80 : 240,
              transition: 'width 0.3s ease',
            }}
          >
            {DrawerMenu}
          </Grid>
        </Hidden>
        <Grid className={classes.contentMaxWidth} item xs>
          <Layout data={DrawerMenu} toTutorial={handleTutorial}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
});

export default connect(mapStateToProps)(PrincipalDashboard);
