/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
// import {useSearchParams} from 'react-router-dom';
import {connect} from 'react-redux';
// import {useLocation} from 'react-router-dom';
import {CSVLink} from 'react-csv';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EditIcon from '@material-ui/icons/Edit';
import PlusOneIcon from '@material-ui/icons/Add';
import {
  listFieldsDatabase, listDataSimulatorDatabaseAdmin,
  deleteDataSimulatorDatabaseAdmin, addDataSimuladorDatabaseAdmin,
  updateDataSimulatorDatabaseAdmin, listSimulatorUserProject,
  getOneDatabase, getOneProject, addDataBucketSimulatorDatabaseAdmin,
} from 'api-lofty';
import toastr from 'toastr';
import Layout from '../layouts/DatabaseDashboard';
import LoadingPage from './LoadingPage';
import Tabular from '../components/Tabular';
import DynamicForm from '../components/DynamicForm';
import {limitText} from '../util/limitString';
import RemoveSimDataDatabaseModal from '../components/RemoveSimDataDatabaseModal';
import BucketDataForm from '../components/BucketDataForm';
import 'toastr/build/toastr.min.css';
import FilterListData from '../components/FilterListsComponent';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const SimulatorDataDatabasePage = ({history, match, token}) => {
  const {params} = match;
  // const location = useLocation();
  const {idProject, idDatabase} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataDatabase, setDataDatabase] = useState({});
  const [openAdd, setOpenAdd] = useState(false);
  const [openAddToBucket, setOpenAddToBucket] = useState(false);
  const [columsData, setColumnsData] = useState([]);
  const [simulatorData, setSimulatorData] = useState([]);
  const [fieldDatabase, setFieldsDatabase] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [relationDatabases, setRelationDatabase] = useState(false);
  const [docUpdate, setDocUpdate] = useState();
  const [arrayTestUser, setArrayTestUser] = useState([]);
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [labelUrl, setLabelUrl] = useState('');
  const [dataCSV, setDataCSV] = useState([]);
  // const [queryParams, setQueryParams] = useState([]);
  // const currentParams = searchParams.get();

  // FOR FILTER
  const [userData, setUserData] = useState([]);
  const [relationData, setRelationData] = useState([]);

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };

  const handleUndoDelete = () => {
    setOpenDialog(false);
  };

  // actualizador de datos
  const handleUpdateData = async (filters, orderBy) => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resDatabase = await getOneDatabase({token, _id: idDatabase});
      const resFields = await listFieldsDatabase({token, idDatabase, idProject});
      const resSimulatorData = await listDataSimulatorDatabaseAdmin({
        token, idDatabase, idProject, paramFilter: filters, orderBy,
      });
      const resTestUser = await listSimulatorUserProject({idProject, token});
      setLabelUrl(`proyectos/${resProject.project.name}/Colección de Datos/${resDatabase.data.label}/Datos`);
      setDataProject(resProject.project);
      setArrayTestUser(resTestUser.data);
      setDataDatabase(resDatabase.data);
      setUserData(resTestUser.data);
      const newColumsData = [];
      if (resDatabase.data.isBucket) {
        newColumsData.push({
          title: 'Nombre Original',
          field: 'loftyOriginalName',
        });
        newColumsData.push({
          title: 'Url',
          field: 'loftyUrl',
        });
      }
      newColumsData.push(({
        title: 'Identificador',
        field: '_id',
      }));
      resFields.data.forEach((item) => {
        if (item.type === 'textrich' || item.type === 'cartArray' || item.type === 'arrayDatabase') {
          const doc = {
            title: item.label,
            render: () => <p>Hay datos</p>,
          };
          newColumsData.push(doc);
        } else {
          const doc = {
            title: item.label,
            field: item.name,
          };
          newColumsData.push(doc);
        }
      });
      newColumsData.push({
        title: 'Acciones',
        field: '_id',
        render: (rowData) => (
          <>
            <IconButton
              onClick={() => {
                setDocUpdate(rowData);
                setOpenAdd(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleOpenDeleteDialog(rowData._id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      });
      const newData = [];
      const newDataRelations = [];
      // obteniendo las base de datos relacionadas
      const relationDatabase = [];
      resFields.data.forEach((field) => {
        if (field.type === 'relation') {
          relationDatabase.push(field.relationDatabase);
        }
      });
      // obteniendo los datos de cada base de datos
      const dataRelationDatabase = [];
      for (const relation of relationDatabase) {
        const resListRelation = await listDataSimulatorDatabaseAdmin({
          idDatabase: relation,
          idProject,
          token,
        });
        const resFieldRelation = await listFieldsDatabase({
          idDatabase: relation,
          idProject,
          token,
        });
        dataRelationDatabase.push({
          database: relation,
          data: resListRelation.data,
          fields: resFieldRelation.data,
        });
      }
      // listando los datos de prueba
      resSimulatorData.data.forEach((item) => {
        const doc = {
          _id: item._id,
        };
        if (resDatabase.data.isBucket) {
          doc.loftyOriginalName = item.data.loftyOriginalName;
          doc.loftyUrl = item.data.loftyUrl;
        }
        resFields.data.forEach((field) => {
          const value = item.data[field.name];
          if (value) {
            if (field.type === 'string') {
              // Validando si posee algun datos
              doc[field.name] = limitText(item.data[field.name]);
            } else if (field.type === 'relation') {
              let defaultValue = 'No definido';
              const databaseRelation = dataRelationDatabase.find((itemRelation) => (
                itemRelation.database === field.relationDatabase
              ));
              if (databaseRelation) {
                const fieldLabelRelation = databaseRelation.fields.find((relationField) => (
                  relationField.isLabel
                ));
                databaseRelation.data.forEach((docRelation) => {
                  if (docRelation._id.toString() === value.toString()) {
                    defaultValue = docRelation.data[fieldLabelRelation?.name];
                  }
                });
              }
              doc[field.name] = limitText(defaultValue);
            } else if (field.type === 'user') {
              const usertester = resTestUser.data.find((usertest) => (
                usertest._id.toString() === value
              ));
              if (usertester) {
                const emailSimulator = usertester.data.loftyEmail;
                doc[field.name] = emailSimulator;
              }
            } else if (field.type === 'coordinates') {
              let lat;
              let lng;
              const coordValue = item.data[field.name];

              if (typeof coordValue === 'object') {
                // Si es un objeto directo
                lat = parseFloat(coordValue.latitude).toFixed(4);
                lng = parseFloat(coordValue.longitude).toFixed(4);
              } else if (typeof coordValue === 'string') {
                try {
                  // Intentar parsear el string JSON
                  const parsed = JSON.parse(coordValue);
                  lat = parseFloat(parsed.latitude).toFixed(4);
                  lng = parseFloat(parsed.longitude).toFixed(4);
                } catch (e) {
                  lat = 0;
                  lng = 0;
                }
              }

              const temp = `[Latitude: ${lat}, Longitude: ${lng}]`;
              doc[field.name] = temp;
            } else if (field.type === 'textrich' || field.type === 'cartArray' || field.type === 'arrayDatabase') {
              doc[field.name] = JSON.stringify(item.data[field.name]);
            } else {
              // Asegurarse de que el valor sea una cadena de texto
              doc[field.name] = typeof item.data[field.name] === 'object'
                ? JSON.stringify(item.data[field.name])
                : String(item.data[field.name]);
            }
          } else {
            doc[field.name] = 'No definido';
          }
        });
        newData.push(doc);
      });
      if (!resDatabase.data?.isBucket) {
        const tempArrayFields = ['_id'];
        const tempArrayNames = ['_id'];
        resFields.data.forEach((index) => {
          tempArrayFields.push(index.name);
          tempArrayNames.push(index.label);
        });
        const csvData = [
          tempArrayNames,
          ...newData.map((index) => {
            const tempValue = [];
            tempArrayFields.forEach((seeker) => {
              tempValue.push((index[seeker]));
            });
            return tempValue;
          }),
        ];
        setDataCSV(csvData);
      }

      setColumnsData(newColumsData);
      setFieldsDatabase(resFields.data);
      setSimulatorData(newData);
      resFields.data.forEach((field) => {
        if (field.type === 'relation') {
          newDataRelations.push({name: field.name, idDatabase: field.relationDatabase});
        }
      });
      const doc = {};
      // get relation Data for all relation fields
      const dataSimFromForeignDB = [];
      newDataRelations.forEach(async (item) => {
        const resDataRelationDatabase = await listDataSimulatorDatabaseAdmin({
          token,
          idDatabase: item.idDatabase,
          idProject,
        });
        const resFieldRelation = await listFieldsDatabase({
          token,
          idProject,
          idDatabase: item.idDatabase,
        });
        // For fields with Relation.
        // Gather all main data (data in foreign db sim, fields of givendb...)
        dataSimFromForeignDB.push({
          relationInfo: item,
          dataSimDatabase: resDataRelationDatabase.data,
          idDatabase: item.idDatabase,
          fieldInfo: item.name,
          fieldMeta: resFieldRelation.data,
        });
        doc[item.name] = {};
        doc[item.name].data = resDataRelationDatabase.data;
        doc[item.name].fields = resFieldRelation.data;
        setRelationDatabase({...relationDatabases, ...doc});
      });
      setRelationData(dataSimFromForeignDB);
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      /*
      const currentParams = new URLSearchParams(location.search);
      console.log(currentParams);
      const paramData = [];
      resFields.data.forEach((index) => {
        const valueParam = currentParams.get(index.name);
        if (valueParam && index.type !== 'string' && index.type
        !== 'user' && index.type !== 'relation') {
          paramData.push({index, value: valueParam});
        } else if (valueParam && index.type === 'string') {
          if (valueParam.includes(',')) {
            const valuesArray = valueParam.split(',');
            const trimmedArray = valuesArray.map((key) => ({value: key.trim()})).filter(Boolean);
            paramData.push({index, value: trimmedArray});
          } else {
            paramData.push({index, value: {value: valueParam}});
          }
        } else if (valueParam && (index.type === 'user' || index.type === 'relation')) {
          if (valueParam.includes(',')) {
            const valuesArray = valueParam.split(',');
            if (index.type === 'string') {
              const trimmedArray = valuesArray.map((key) => ({value: key.trim()})).filter(Boolean);
              paramData.push({index, value: trimmedArray});
            } else {
              const trimmedArray = valuesArray.map((key) => ({_id: key.trim()})).filter(Boolean);
              paramData.push({index, _id: trimmedArray});
            }
          } else {
            paramData.push({index, value: {_id: valueParam}});
          }
        }
      });
      // console.log(paramData);
      // setQueryParams(paramData);
      */
      // setTimeout(() => toastr.success('Datos cargados'), 300);
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };
  useEffect(async () => {
    handleUpdateData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const handleOpenAdd = () => {
    setOpenAdd((prev) => !prev);
  };

  const handleOpenAddBucket = () => {
    setOpenAddToBucket((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const body = {};
      fieldDatabase.forEach((field) => {
        if (field.type === 'cartArray' || field.type === 'arrayDatabase') {
          const rawValue = e.target[field.name].value;
          const parsedValue = JSON.parse(rawValue);
          // Transformar al formato correcto antes de enviar
          body[field.name] = parsedValue.map((item) => {
            if (field.type === 'cartArray') {
              const result = {
                cartitem: item.cartitem,
                quantity: item.quantity || 1,
              };
              return result;
            }
            const result = {
              document: item.document,
            };
            return result;
          });
        } else if (field.type === 'boolean') {
          body[field.name] = e.target[field.name].checked;
        } else if (field.type === 'date') {
          body[field.name] = new Date(e.target[field.name].value);
        } else if (field.type === 'coordinates') {
          const latitude = parseFloat(parseFloat(e.target.latitude.value).toFixed(4));
          const longitude = parseFloat(parseFloat(e.target.longitude.value).toFixed(4));
          body[field.name] = {latitude, longitude};
        } else if (field.type === 'textrich') {
          body[field.name] = JSON.parse(e.target[field.name].value);
        } else {
          body[field.name] = e.target[field.name].value;
        }
      });

      if (docUpdate) {
        await updateDataSimulatorDatabaseAdmin({
          token,
          _id: docUpdate._id,
          body,
          idDatabase,
          idProject,
        });
      } else {
        await addDataSimuladorDatabaseAdmin({
          body,
          idDatabase,
          idProject,
          token,
        });
      }
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Exito'), 300);
      handleUpdateData();
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Error no se pudo agregar el dato'), 300);
      }
    }
    setLoadingForm(false);
  };

  const handleSubmitBucket = async (e, file) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const body = [];
      fieldDatabase.forEach((field) => {
        if (field.type === 'boolean') {
          const temp = {name: field.name, value: e.target[field.name].checked};
          body.push(temp);
        } else if (field.type === 'date') {
          const dateTemp = new Date(e.target[field.name].value);
          const temp = {name: field.name, value: dateTemp};
          body.push(temp);
        } else if (field.type === 'coordinates') {
          const latitude = parseFloat(parseFloat(e.target[`${field.name}_latitude`].value).toFixed(4));
          const longitude = parseFloat(parseFloat(e.target[`${field.name}_longitude`].value).toFixed(4));
          const temp = {
            name: field.name,
            value: JSON.stringify({latitude, longitude}),
          };
          body.push(temp);
        } else {
          const temp = {name: field.name, value: e.target[field.name].value};
          body.push(temp);
        }
      });
      await addDataBucketSimulatorDatabaseAdmin({
        token,
        idDatabase,
        idProject,
        body,
        file,
      });
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Exito'), 300);
      handleUpdateData();
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Error no se pudo agregar el dato'), 300);
      }
    }
    setLoadingForm(false);
  };

  const handleParamAnchor = async (params, orderBy) => {
    const paramsCapture = [];
    const cleanedFromNoise = params.filter((index) => (
      index.value.length > 0 || index.value === 0
    ));
    cleanedFromNoise.forEach((obj) => {
      if (obj.index?.type === 'string') {
        const filteredString = obj.value.map((valdata) => (valdata.value));
        paramsCapture.push({param: obj.index.name, value: filteredString});
      } else if (obj.index?.type === 'relation' || obj.index?.type === 'user') {
        const filteredID = obj.value.map((valdata) => (valdata._id));
        paramsCapture.push({param: obj.index.name, value: filteredID});
      } else {
        paramsCapture.push({param: obj.index.name, value: obj.value});
      }
    });
    if (paramsCapture.length > 0) {
      // const currentParams = new URLSearchParams(location.search);
      // paramsCapture.forEach((key) => {
      //   const {param, value} = key;
      //   // Check if the value is an array
      //   if (Array.isArray(value)) {
      //     // Serialize the array using a delimiter (e.g., comma) and set the parameter
      //     currentParams.set(param, value.join(','));
      //   } else {
      //     // For non-array values, simply set the parameter
      //     currentParams.set(param, value);
      //   }
      // });
      // history.replace({
      //   pathname: location.pathname,
      //   search: `?${currentParams.toString()}`,
      // });
      const paramFilter = JSON.stringify(paramsCapture);
      // const getSomeInfo = await listDataSimulatorDatabaseAdmin({
      //   token, idDatabase, idProject, paramFilter,
      // });
      handleUpdateData(paramFilter, orderBy);
    } else {
      // history.replace({
      //   pathname: location.pathname,
      //   search: '',
      // });
      handleUpdateData();
    }
    // setSearchParams(params);
  };

  return (
    <Layout
      history={history}
      databaseName={dataDatabase?.label}
      projectName={dataProject?.name}
      idDatabase={idDatabase}
      idProject={idProject}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box className={classes.descriptionSection}>
        <ErrorOutlineIcon style={{color: 'rgba(20, 86, 158, 1)'}} />
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          La seccion de datos simulados contiene todos los datos guardados dentro
          de la coleccion de datos que se agregaron en la simulacion del proyecto
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}/database`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Datos
          </Typography>
        </Box>
        <Box component="div">
          {!dataDatabase?.isBucket && (
            <CSVLink
              className="MuiButtonBase-root MuiButton-root MuiButton-contained makeStyles-textButton-180 MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall"
              style={{
                color: '#fff',
                marginLeft: '5px',
                marginRight: '5px',
                fontWeight: 'bold',
                backgroundColor: 'rgba(47, 129, 50, 1)',
                fontFamily: [
                  'Nunito',
                  'Roboto',
                  'Helvetica Neue',
                  'Arial',
                  'sans-serif',
                ].join(','),
                textTransform: 'none',
              }}
              filename="simulation-data.csv"
              data={dataCSV}
            >
              Exportar a CSV
            </CSVLink>
          )}
          {
          !dataDatabase?.isBucket ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.textButton}
              startIcon={<PlusOneIcon />}
              onClick={() => handleOpenAdd()}
              style={{
                color: 'black',
                fontWeight: 'bold',
                backgroundColor: '#29B2EF',
                fontFamily: [
                  'Nunito',
                  'Roboto',
                  'Helvetica Neue',
                  'Arial',
                  'sans-serif',
                ].join(','),
                textTransform: 'none',
              }}
            >
              Agregar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.textButton}
              startIcon={<PlusOneIcon />}
              onClick={() => handleOpenAddBucket()}
              style={{
                color: 'black',
                fontWeight: 'bold',
                backgroundColor: '#29B2EF',
                fontFamily: [
                  'Nunito',
                  'Roboto',
                  'Helvetica Neue',
                  'Arial',
                  'sans-serif',
                ].join(','),
                textTransform: 'none',
              }}
            >
              Agregar
            </Button>
          )
        }
          {' '}
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.textButton}
            onClick={handleUpdateData}
            style={{
              color: '#ffffff',
              fontWeight: 'bold',
              fontFamily: [
                'Nunito',
                'Roboto',
                'Helvetica Neue',
                'Arial',
                'sans-serif',
              ].join(','),
              textTransform: 'none',
            }}
          >
            Actualizar Tabla
          </Button>
        </Box>
      </Box>
      <DynamicForm
        fields={fieldDatabase}
        loading={loadingForm}
        onClose={() => {
          setDocUpdate(undefined);
          setOpenAdd(!openAdd);
        }}
        open={openAdd}
        handleSubmit={handleSubmit}
        relation={relationDatabases}
        docUpdate={docUpdate}
        arrayTestUser={arrayTestUser}
        idProject={idProject}
        token={token}
      />
      <BucketDataForm
        fields={fieldDatabase}
        loading={loadingForm}
        onClose={() => {
          setDocUpdate(undefined);
          setOpenAddToBucket(!openAddToBucket);
        }}
        open={openAddToBucket}
        handleSubmit={(e, file) => handleSubmitBucket(e, file)}
        relation={relationDatabases}
        docUpdate={docUpdate}
        arrayTestUser={arrayTestUser}
        idProject={idProject}
        token={token}
      />
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        <Box
          style={{
            padding: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <FilterListData
            fieldsComponent={fieldDatabase}
            data={simulatorData}
            dataRelation={relationData}
            dataUsers={userData}
            handleParamUpdate={(e, orderBy) => handleParamAnchor(e, orderBy)}
            // paramFromUrl={queryParams}
          />
        </Box>
        <Tabular
          columns={columsData}
          ready={loading}
          title="Datos"
          data={simulatorData}
        />
      </Box>
      <div>
        <RemoveSimDataDatabaseModal
          open={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
          undoDelete={handleUndoDelete}
          handleDeletion={async () => {
            try {
              await deleteDataSimulatorDatabaseAdmin({
                token,
                idDatabase,
                idProject,
                _id: idDeletion,
              });
              toastr.options = {
                positionClass: 'toast-top-right',
                hideDuration: 300,
                timeOut: 6000,
              };
              setOpenDialog(!openDialog);
              handleUpdateData();
              toastr.clear();
              setTimeout(() => toastr.success('Se elimino exitosamente el dato'), 300);
            } catch (error) {
              if (error.info) {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
              } else {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error('error: \n No se elimino el dato'), 300);
              }
            }
          }}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(SimulatorDataDatabasePage);
