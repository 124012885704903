import {makeStyles} from '@material-ui/core/styles';

export const useSidebarStyles = makeStyles((theme) => ({
  exitItemTypo: {
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '16px',
    marginLeft: '6%',
    marginTop: '10%',
    [theme.breakpoints.down('lg')]: {fontSize: '13px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  fontItemsStyle: {
    fontSize: '16px',
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('1280')]: {fontSize: '11px'},
    [theme.breakpoints.down('1100')]: {fontSize: '9px'},
    [theme.breakpoints.down('950')]: {fontSize: '12px'},
  },
  exitIconStyle: {
    zIndex: 1,
    marginTop: '40px',
    marginBottom: '10%',
    width: '25px',
    height: '25px',
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 0.3s ease',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    backgroundColor: '#144999',
    position: 'static',
    height: '100vh',
    borderRight: '1px solid #fff',
  },
  drawerExpanded: {
    width: 240,
  },
  drawerCollapsed: {
    width: 80,
  },
  menuListContainer: {
    flex: 1,
    marginLeft: '3%',
    overflowY: 'auto',
  },
  menuItemCollapsed: {
    paddingLeft: '22px',
    '& .MuiTypography-root': {
      width: 0,
      opacity: 0,
      transition: 'width 0.3s ease, opacity 0.3s ease',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  menuItemExitCollapsed: {
    '& .MuiTypography-root': {
      width: 0,
      opacity: 0,
      transition: 'width 0.3s ease, opacity 0.3s ease',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  toggleButton: {
    position: 'absolute',
    top: 20,
    right: -30,
    transform: 'translateX(-50%)',
    backgroundColor: '#144999',
    color: '#ffffff',
    border: '1px solid #fff',
    '&:hover': {
      backgroundColor: '#144999',
    },
  },

  contentMaxWidth: {
    maxWidth: '84%',
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
    margin: '10px 20px',
    [theme.breakpoints.down('1750')]: {maxWidth: '82%'},
    [theme.breakpoints.down('1556')]: {maxWidth: '80%'},
    [theme.breakpoints.down('1400')]: {maxWidth: '77%'},
    [theme.breakpoints.down('1218')]: {maxWidth: '74%'},
    [theme.breakpoints.down('1077')]: {maxWidth: '70%'},
    [theme.breakpoints.down('960')]: {maxWidth: '100%'},
  },
}));
