import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  Grid, Box, MenuList,
  ListItemIcon, Typography,
  Hidden, IconButton, // Button,
} from '@material-ui/core';
// import Swal from 'sweetalert2';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import Layout from './Dashboard';
import SidebarItem from '../components/Sidebar/SidebarItem';
import {createStyledMenuItem} from '../components/Sidebar/menuItemFactory';
import {useSidebarStyles} from '../components/Sidebar/sidebarStyles';
import imgLogo from '../img/dashhboard/logo-w_1.png';
import imgLogoCollapsed from '../img/Logo-07.png';
import exitImg from '../img/icons/salir.svg';
import imgDoc1 from '../img/icons/documentacion_w.svg';
import imgDoc2 from '../img/icons/documentacion_b.svg';
import imgActivity1 from '../img/icons/actividad_w.svg';
import imgActivity2 from '../img/icons/actividad_b.svg';
import imgField1 from '../img/icons/campos_w.svg';
import imgField2 from '../img/icons/campos_b.svg';
import imgConfig1 from '../img/icons/configuracion_w.svg';
import imgConfig2 from '../img/icons/configuracion_b.svg';
import imgData1 from '../img/icons/datos_w.svg';
import imgData2 from '../img/icons/datos_b.svg';
import imgPermission1 from '../img/icons/permisos_w.svg';
import imgPermission2 from '../img/icons/permisos_b.svg';

const StyledMenuField = createStyledMenuItem(imgField1, imgField2);
const StyledMenuPermission = createStyledMenuItem(imgPermission1, imgPermission2);
const StyledMenuDoc = createStyledMenuItem(imgDoc1, imgDoc2);
const StyledMenuData = createStyledMenuItem(imgData1, imgData2);
const StyledMenuActivity = createStyledMenuItem(imgActivity1, imgActivity2);
const StyledMenuConfig = createStyledMenuItem(imgConfig1, imgConfig2);

const UserProjectDashboard = ({
  children, history, idProject,
  idUserProject, // projectName, token, userProjectType,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const classes = useSidebarStyles();

  const DrawerMenu = (
    <Box>
      <Box className={`${classes.drawerContainer} ${isCollapsed ? classes.drawerCollapsed : classes.drawerExpanded}`}>
        <Hidden smDown>
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={classes.toggleButton}
            size="small"
          >
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Hidden>
        <Box
          component="img"
          src={isCollapsed ? imgLogoCollapsed : imgLogo}
          alt="logo"
          style={{
            zIndex: 1, marginTop: 'auto', marginBottom: 'auto', marginLeft: isCollapsed ? '-10px' : 0,
          }}
        />
        <MenuList autoFocusItem className={classes.menuListContainer}>
          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Campos">
            <StyledMenuField
              selected={window.location.pathname === `/dashboard/project/${idProject}/users/${idUserProject}/fields`}
              onClick={() => history.push(`/dashboard/project/${idProject}/users/${idUserProject}/fields`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Campos
              </Typography>
            </StyledMenuField>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Roles">
            <StyledMenuPermission
              selected={window.location.pathname === `/dashboard/project/${idProject}/users/${idUserProject}/roles`}
              onClick={() => history.push(`/dashboard/project/${idProject}/users/${idUserProject}/roles`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Roles
              </Typography>
            </StyledMenuPermission>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Documentación">
            <StyledMenuDoc
              selected={window.location.pathname === `/dashboard/project/${idProject}/users/${idUserProject}/documentation`}
              onClick={() => history.push(`/dashboard/project/${idProject}/users/${idUserProject}/documentation`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Documentación
              </Typography>
            </StyledMenuDoc>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Datos">
            <StyledMenuData
              selected={window.location.pathname === `/dashboard/project/${idProject}/users/${idUserProject}/data`}
              onClick={() => history.push(`/dashboard/project/${idProject}/users/${idUserProject}/data`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Datos
              </Typography>
            </StyledMenuData>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Configuración">
            <StyledMenuConfig
              selected={window.location.pathname === `/dashboard/project/${idProject}/users/${idUserProject}/settings`}
              onClick={() => history.push(`/dashboard/project/${idProject}/users/${idUserProject}/settings`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Configuración
              </Typography>
            </StyledMenuConfig>
          </SidebarItem>

          <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Actividad">
            <StyledMenuActivity
              selected={window.location.pathname === `/dashboard/project/${idProject}/users/${idUserProject}/activities`}
              onClick={() => history.push(`/dashboard/project/${idProject}/users/${idUserProject}/activities`)}
              className={isCollapsed ? classes.menuItemCollapsed : ''}
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Actividad
              </Typography>
            </StyledMenuActivity>
          </SidebarItem>
        </MenuList>
        <SidebarItem isCollapsed={isCollapsed} tooltipTitle="Salir de Usuario">
          <Box
            component="div"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              alignItems: 'flex-end',
              minHeight: '100px',
            }}
            className={isCollapsed ? classes.menuItemExitCollapsed : ''}
          >
            <Link to={`/dashboard/project/${idProject}`}>
              <Box
                component="img"
                src={exitImg}
                alt="logo"
                className={classes.exitIconStyle}
              />
            </Link>
            <Typography className={classes.exitItemTypo}>
              Salir de Usuario
            </Typography>
          </Box>
        </SidebarItem>
      </Box>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Grid container spacing={0} style={{width: '100%', height: '100%'}}>
        <Hidden smDown>
          <Grid
            item
            style={{
              position: 'relative',
              height: '100%',
              width: isCollapsed ? 80 : 240,
              transition: 'width 0.3s ease',
            }}
          >
            {DrawerMenu}
          </Grid>
        </Hidden>
        <Grid className={classes.contentMaxWidth} item xs>
          <Layout data={DrawerMenu}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(UserProjectDashboard);
