/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import {
  isInvalidSlug,
  listDatabases, listEndpoint,
} from 'api-lofty';
import toastr from 'toastr';
import {
  Dialog, Box, IconButton,
  Button, FormControlLabel, Switch, MenuItem,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import {stringTypeLabelValue} from '../util/typesFields';
import 'toastr/build/toastr.min.css';
import {generateSlugSampleProject} from '../util/generateSuggestionSlug';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
}));

const AddParamModal = ({
  handleClose, open, handleForm, typeEndpoint, token, idProject,
}) => {
  const classes = useStyle();
  const [typeofField, setTypeOfField] = useState(false);
  const [typeString, setTypeString] = useState('');
  const [checkedDefaultValues, setCheckedDefaultValues] = useState(false);
  const [defaultValuesData, setDefaultValueData] = useState([]);
  const [defaultValueString, setDefaultValueString] = useState('');
  const [defaultValueName, setDefaultValueName] = useState('');
  const [Required, setRequired] = useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [errorDefValue, setErrorDefValue] = useState(false);
  const [foundError, setFoundError] = useState(false);
  const [typeParam, setTypeParam] = useState('body');
  const [suggestionSlug, setSuggestionSlug] = useState('');
  const [errorInSlug, setErrorInSlug] = useState(false);
  const [errorMessageSlug, setErrorMessageSlug] = useState('');

  // For Relation fields
  const [databases, setDatabases] = useState([]);
  const [selectedRelation, setSelectedRelation] = useState('');
  const [allRelationEndpoints, setAllRelationEndpoints] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState('');

  function generateSuggestionSlug(value) {
    try {
      if (value) {
        const res = generateSlugSampleProject([], value);
        setSuggestionSlug(res);
        // Verify the generated slug
        if (isInvalidSlug(res)) {
          setErrorText('Solo letras minusculas y numeros son aceptados');
          setHasError(true);
        } else {
          setErrorText('');
          setHasError(false);
        }
      }
    } catch (error) {
      setErrorText('Error al generar el slug');
      setHasError(true);
    }
  }

  function findData() {
    let retVal = false;
    if (defaultValuesData.find((element) => element.slug === defaultValueString)) {
      retVal = true;
    }
    return retVal;
  }

  const addDefaultValueChecker = () => {
    if (!defaultValueName.trim() || !defaultValueString.trim()) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('error: \n No se pueden agregar valores vacíos'), 300);
      return;
    }

    if (!findData() && !foundError) {
      const obj = {label: defaultValueName, slug: defaultValueString};
      setDefaultValueData((prevArray) => [...prevArray, obj]);
      setDefaultValueString('');
      setDefaultValueName('');
    } else if (foundError) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('error: \n El Slug no es valido'), 300);
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('error: \n Ya existe la variable. \n Verifique el slug'), 300);
    }
  };

  const verifySlugDefaultValue = (e) => {
    const newValue = e.target.value;
    setDefaultValueString(e.target.value);
    if (isInvalidSlug(newValue)) {
      setErrorDefValue('Solo letras minusculas y numeros son aceptados');
      setFoundError(true);
    } else {
      setErrorDefValue('');
      setFoundError(false);
    }
  };

  const removeDefaultValue = (value) => {
    setDefaultValueData((current) => current.filter((data) => data.value !== value));
  };

  async function updateRelationEndpoints(relationValue) {
    try {
      const searchEndpoints = await listEndpoint({idDatabase: relationValue, token});
      const filterDataBeforeSetting = searchEndpoints.data.filter((index) => (
        index.typeFunction === 'list' || (index.typeFunction === 'customList' && !index.isAuthentication && index?.responseDatabase === relationValue)
      ));
      setAllRelationEndpoints(filterDataBeforeSetting);
      setSelectedRelation(relationValue);
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('error: \n No se encontraron los servicios de la coleccion'), 300);
    }
  }

  const verifySlugValue = (value) => {
    if (isInvalidSlug(value)) {
      setErrorMessageSlug('Verifique que no tenga espacios/palabras reservadas');
      setErrorInSlug(true);
    } else {
      setErrorInSlug(false);
      setErrorMessageSlug('');
    }
  };

  const resetForm = () => {
    setTypeOfField(false);
    setTypeString('');
    setCheckedDefaultValues(false);
    setDefaultValueData([]);
    setDefaultValueString('');
    setDefaultValueName('');
    setRequired(false);
    setHasError(false);
    setErrorText('');
    setErrorDefValue(false);
    setFoundError(false);
    setTypeParam('body');
    setSuggestionSlug('');
    setErrorInSlug(false);
    setErrorMessageSlug('');
    setSelectedRelation('');
    setSelectedEndpoint('');
  };

  // Add useEffect to reset form when modal opens/closes
  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(async () => {
    try {
      const resDatabase = await listDatabases({token, idProject});
      setDatabases(resDatabase.data);
    } catch (error) {
      // console.log(error);
    }
  }, []);

  return (
    <Dialog
      open={open}
      handleClose={() => {
        resetForm();
        if (handleClose) handleClose();
      }}
    >
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar parámetro
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => {
              resetForm();
              if (handleClose) { handleClose(); }
            }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{padding: '10px', margin: '5px', width: '500px'}}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!suggestionSlug.trim() || !typeofField) {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error('error: \n Los campos Etiqueta y Tipo son requeridos'), 300);
                return;
              }

              if (selectedRelation && !selectedEndpoint && typeofField === 'relation') {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error('error: \n No se encontro un servicio seleccionado para el parametro'), 300);
              } else {
                handleForm(e, Required, defaultValuesData, selectedRelation || undefined, selectedEndpoint || undefined, typeString || undefined);
                resetForm();
                handleClose();
              }
            }}
          >
            <div className="mt-4">
              <TextField
                fullWidth
                name="label"
                type="text"
                label="Etiqueta"
                variant="outlined"
                onChange={(e) => generateSuggestionSlug(e.target.value)}
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <TextField
                fullWidth
                name="name"
                type="text"
                label="Slug"
                variant="outlined"
                value={suggestionSlug}
                onChange={(e) => setSuggestionSlug(e.target.value)}
                onBlur={(e) => verifySlugValue(e.target.value)}
                error={hasError || errorInSlug}
                helperText={errorText || errorMessageSlug}
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <TextField
                fullWidth
                name="type"
                label="Tipo"
                type="text"
                variant="outlined"
                select
                onChange={(e) => setTypeOfField(e.target.value)}
              >
                <MenuItem value="string">
                  Cadena de Texto
                </MenuItem>
                <MenuItem value="number">
                  Número
                </MenuItem>
                <MenuItem value="boolean">
                  VoF (Booleano)
                </MenuItem>
                {
                  (typeEndpoint === 'customCreate' || (typeEndpoint === 'customUpdate' && typeParam === 'body')) && (
                    <MenuItem value="date">
                      Fecha
                    </MenuItem>
                  )
                }
                {
                  (typeEndpoint === 'customCreate' || (typeEndpoint === 'customUpdate' && typeParam === 'body')) && (
                    <MenuItem value="textrich">
                      Texto Enriquecido
                    </MenuItem>
                  )
                }
                {
                  (typeEndpoint === 'customCreate' || (typeEndpoint === 'customUpdate' && typeParam === 'body')) && (
                    <MenuItem value="relation">
                      Relacion de Coleccion de Datos
                    </MenuItem>
                  )
                }
                {
                  typeEndpoint === 'customCreate' && (
                    <MenuItem value="loftyfile">
                      Archivo
                    </MenuItem>
                  )
                }
                {
                  (typeEndpoint === 'customCreate' || (typeEndpoint === 'customUpdate' && typeParam === 'body')) && (
                    <MenuItem value="cartArray">
                      Carrito de Compras
                    </MenuItem>
                  )
                }
                {
                  (typeEndpoint === 'customCreate' || (typeEndpoint === 'customUpdate' && typeParam === 'body')) && (
                    <MenuItem value="arrayDatabase">
                      Lista de colección de dato
                    </MenuItem>
                  )
                }
              </TextField>
            </div>
            {typeofField === 'string' && (
            <div className="my-4">
              <TextField
                onChange={(e) => setTypeString(e.target.value)}
                value={typeString}
                select
                fullWidth
                name="typeString"
                type="text"
                label="Tipo de String"
                variant="standard"
              >
                {
                stringTypeLabelValue.map(({label, value}) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))
              }
              </TextField>
            </div>
            )}
            {typeofField === 'string' && (
            <div className="my-4">
              <FormControlLabel
                control={
                  <Checkbox checked={checkedDefaultValues} onClick={() => setCheckedDefaultValues(!checkedDefaultValues)} name="checkedB" color="primary" />
                }
                label="Valores Por defecto"
              />
            </div>
            )}
            {checkedDefaultValues && typeofField === 'string' && (
            <div className="my-4">
              <div className="my-4">
                <b>Valores por defecto</b>
              </div>
              <div className="my-4">
                <TextField
                  fullWidth
                  type="text"
                  label="Etiqueta"
                  variant="standard"
                  value={defaultValueName}
                  onChange={(e) => setDefaultValueName(e.target.value)}
                />
              </div>
              <div className="my-4">
                <TextField
                  fullWidth
                  type="text"
                  label="Slug"
                  variant="standard"
                  value={defaultValueString}
                  onChange={verifySlugDefaultValue}
                  helperText={errorDefValue}
                  error={foundError}
                />
              </div>
              <Box component="div" className="my-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addDefaultValueChecker}
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: '#29B2EF',
                    fontFamily: [
                      'Nunito',
                      'Roboto',
                      'Helvetica Neue',
                      'Arial',
                      'sans-serif',
                    ].join(','),
                    textTransform: 'none',
                  }}
                >
                  Agregar Valor Por defecto
                </Button>
              </Box>
              <Box component="div" className="my-4">
                <List className={classes.root}>
                  {defaultValuesData.map((option) => (
                    <ListItem>
                      <ListItemText
                        primary={option.label}
                        secondary={option.slug}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => removeDefaultValue(option.value)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </div>
            )}
            {
            (
              typeofField === 'relation'
              || typeofField === 'arrayDatabase'
              || typeofField === 'cartArray'
            ) && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="relation"
                  type="text"
                  label="Relación de Colección de Datos"
                  variant="standard"
                  onChange={(e) => updateRelationEndpoints(e.target.value)}
                >
                  {
                    databases.map(({_id, label}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
            }
            {
            typeofField === 'relation' && selectedRelation !== '' && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="endpoint"
                  type="text"
                  label="Servicios de Coleccion de Datos"
                  variant="standard"
                  onChange={(e) => setSelectedEndpoint(e.target.value)}
                >
                  {
                    allRelationEndpoints.map(({_id, url}) => (
                      <MenuItem value={_id} key={_id}>
                        {url}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
            }
            {
              typeEndpoint === 'customUpdate' && (
                <div className="mt-4">
                  <TextField
                    fullWidth
                    name="typeParam"
                    label="Ubicacion"
                    type="text"
                    variant="outlined"
                    select
                    onChange={(e) => setTypeParam(e.target.value)}
                    defaultValue={typeParam}
                  >
                    <MenuItem value="body">
                      Formulario
                    </MenuItem>
                    <MenuItem value="query">
                      Variable o Parametro
                    </MenuItem>
                  </TextField>
                </div>
              )
            }
            <div className="mt-4">
              <FormControlLabel
                control={(
                  <Switch
                    checked={Required}
                    onChange={() => setRequired((prev) => !prev)}
                  />
                )}
                label="Requerido"
                value={Required}
                name="required"
              />
            </div>
            <div className="mt-4 text-center">
              <Button type="submit" color="primary" variant="contained" className={classes.buttonStyle}>
                Agregar
              </Button>
            </div>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddParamModal;
