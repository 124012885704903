/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import toastr from 'toastr';
import {Box, Typography, IconButton} from '@material-ui/core';
import PlusOneIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {deleteCronJob, getOneProject, listCronJobsByProject} from 'api-lofty';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
// import {listDatabases} from '../api/database.api';
// import {getOneProject} from '../api/project.api';
import 'toastr/build/toastr.min.css';
import ListCronJobs from '../components/ListCronJobs';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const CronJobDashboard = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  const handleListData = async () => {
    const getProjectInfo = await getOneProject({token, idProject});
    setLabelUrl(`proyectos/${getProjectInfo.project.name}/CronJobs`);
    setProject(getProjectInfo.project);
    setLoading(false);
    try {
      const getCronJobs = await listCronJobsByProject({token, projectId: idProject});
      setData(getCronJobs.data);
    } catch (error) {
      setData([]);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleListData();
  }, []);

  const handleRedirect = () => {
    history.push(`/dashboard/project/${idProject}/customcronjob`);
  };

  const handleDeleteCronJob = async (e) => {
    try {
      await deleteCronJob({token, _id: e});
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se elimino el cron job'), 300);
      handleListData();
    } catch (error) {
      // console.log(error);
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos no se pudo eliminar el cron job'), 300);
      }
    }
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box className={classes.descriptionSection}>
        <ErrorOutlineIcon style={{color: 'rgba(20, 86, 158, 1)'}} />
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          La seccion contiene todos los cron jobs del proyecto
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push('/dashboard')}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Cron Jobs
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={classes.textButton}
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          startIcon={<PlusOneIcon />}
          onClick={() => handleRedirect()}
        >
          Agregar Cron Job
        </Button>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          padding: '10px',
          minHeight: '400px',
          width: '100%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          p: 1,
          m: 1,
        }}
      >
        {
        data.length === 0 ? (
          <h3 className="text-center mt-5">
            Aún no has agregado cron jobs
          </h3>
        ) : (
          <Box component="div" sx={{width: '100%'}}>
            <ListCronJobs
              data={data}
              handleDelete={(e) => handleDeleteCronJob(e)}
            />
          </Box>
        )
      }
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(CronJobDashboard);
