import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {Box, IconButton} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainBox: {
    padding: '15px',
    width: '100%',
    height: 'auto',
    // overflowY: 'scroll',
  },
  headerSection: {
    width: '100%',
    display: 'flex',
    height: '75px',
    flexDirection: 'row',
    borderBottom: '1px solid rgba(217, 217, 217, 1)',
    backgroundColor: '#EEF7FF',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EAECF0',
    borderBottom: '1px solid #EAECF0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerColumn: {
    width: '25%',
    padding: '5px',
    textAlign: 'center',
    color: '#667085',
    fontWeight: 500,
    fontSize: '18px',
  },
  contentColumn: {
    width: '25%',
    padding: '5px',
    textAlign: 'center',
    color: '#667085',
    fontWeight: 400,
  },
  iconStyleColor: {
    color: '#201549',
    '&:hover': {
      color: '#D9D9D9',
    },
  },
}));

export default function ListCronJobs({
  data, handleDelete,
}) {
  const classes = useStyles();
  function typeOfCronSchedule(schedule) {
    switch (schedule) {
      case '0 */1 * * *':
        return '1 hora';
      case '0 */2 * * *':
        return ' 2 horas';
      case '0 */8 * * *':
        return '8 horas';
      case '0 */12 * * *':
        return '12 horas';
      case '0 0 * * *':
        return '24 horas (1 dia)';
      case '0 0 */2 * *':
        return '48 horas (2 dias)';
      case '0 0 */3 * *':
        return '72 horas (3 dias)';
      case '0 0 * * 0':
        return '1 semana';
      default:
        return '';
    }
  }
  return (
    <Box component="div" className={classes.mainBox}>
      <Box component="div" className={classes.headerSection}>
        <Box component="div" className={classes.headerColumn}>
          label
        </Box>
        <Box component="div" className={classes.headerColumn}>
          Slug
        </Box>
        <Box component="div" className={classes.headerColumn}>
          Tiempo
        </Box>
        <Box component="div" className={classes.headerColumn}>
          Acciones
        </Box>
      </Box>
      {data.map((index) => (
        <Box component="div" className={classes.contentSection}>
          <Box component="div" className={classes.contentColumn} style={{fontWeight: 600}}>
            {index.name}
          </Box>
          <Box component="div" className={classes.contentColumn}>
            {index?.command}
          </Box>
          <Box component="div" className={classes.contentColumn}>
            {typeOfCronSchedule(index?.schedule)}
          </Box>
          <Box component="div" className={classes.contentColumn}>
            {/* <IconButton
              className={classes.iconStyleColor}
            >
              <EditIcon />
            </IconButton> */}
            <IconButton
              className={classes.iconStyleColor}
              onClick={() => handleDelete(index._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
